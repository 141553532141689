import { useEffect, useState, useMemo } from 'react';
import { createUser, getUser, setup, getUsers, updateUser } from './api/firebase';
import publicIp from 'public-ip';
import classnames from 'classnames';
import Header from './components/header';
import Main from './components/main';
import About from './components/about';
import Project from './components/project';
import Footer from './components/footer';
import styles from './home.module.scss';


export default function HomePage() {
  const [ip, setIp] = useState('');
  const [user, setUser] = useState('');
  const [topicSelected, setTopicSelected] = useState(0);

  const topicData = useMemo(() => [
    {
      topicName: 'influencer', 
      name: 'Nhân vật của năm'
    }, 
    {
      topicName: 'event', 
      name: 'Sự kiện của năm', 
    }, 
    {
      topicName: 'project', 
      name: 'Dự án của năm', 
    }, 
    {
      topicName: 'comunity', 
      name: 'CỘNG ĐỒNG CỦA NĂM'
    }
  ], []);

  const handleCreateUser = async () => {
    const exixts:any = await getUser(ip); 
    if (exixts) {
      setUser(exixts);
    } else {
      const payload:any = {
        ip, 
        votted: {
          influencer: [], 
          comunities: [], 
        }
      }
      createUser(payload);
      setUser(payload);
    }
  }

  const handleModify = async () => {
    const userList:any = await getUsers();
    userList.forEach(async (user: any) => {
      if (user?.votted?.influencer) {
        if (user?.votted?.influencer.includes('Nguyễn Thành Trung'.trim()) || user?.votted?.influencer.includes('Nguyễn Thế Vinh'.trim())) {
          const tmp = [];
          if (user?.votted?.influencer.includes('Nguyễn Thành Trung'.trim())) {
            tmp.push('nguyenthanhtrung');
          }
          if (user?.votted?.influencer.includes('Nguyễn Thế Vinh'.trim())) {
            tmp.push('nguyenthevinh');
          }
          const arr:any = []; 
          user?.votted?.influencer?.forEach((item: any) => {
            if (item === 'Nguyễn Thành Trung' || item === 'Nguyễn Thế Vinh') {
              return; 
            }
            arr.push(item);
          })
          tmp.forEach(item => arr.push(item));
          const newItem = { ... user, votted: { ... user.votted, influencer: arr }};
          await updateUser(newItem?.id, newItem)
        }
      }
    })
  }

  const handleSetupData = async (index: number) => {
    if (index >= topicData.length) {
      return; 
    }

    await setup(topicData[index].topicName);
    handleSetupData(index + 1);
  }

  const handleGetIp = async () => {
    const ip = await publicIp.v4();
    setIp(ip);
  }

  const handleChangeTopic = (index: number) => {
    setTopicSelected(index);
  }

  useEffect(() => {
    // handleSetupData(0);
    handleGetIp();
  }, []);

  useEffect(() => {
    if (ip) {
      handleCreateUser();
    }
  }, [ ip ]);

  // useEffect(() => {
  //   handleModify();
  // }, []);


  return (
    <>
      <Header enableBrandPanel={false} enableVerticalPanel={true} />
      <div className="layout">
        <Main />
        <div className="content">
          <About />
          <div className="topic-wrapper">
            <nav className={styles['slide-tab-panel']}>
              <div className={styles.container}>
                <ul>
                  <li className={classnames(styles.one, { [styles.active]: topicSelected === 0 }, 'clickable uppercase')} onClick={() => handleChangeTopic(0)}><a>{topicData[0]?.name}</a></li>
                  <li className={classnames(styles.two, { [styles.active]: topicSelected === 1 }, 'clickable uppercase')} onClick={() => handleChangeTopic(1)}><a>{topicData[1]?.name}</a></li>
                  <li className={classnames(styles.three, { [styles.active]: topicSelected === 2 }, 'clickable uppercase')} onClick={() => handleChangeTopic(2)}><a>{topicData[2]?.name}</a></li>
                  <li className={classnames(styles.four, { [styles.active]: topicSelected === 3 }, 'clickable uppercase')} onClick={() => handleChangeTopic(3)}><a>{topicData[3]?.name}</a></li>
                  <hr />
                </ul>
              </div>
            </nav>
          </div>
          <Project topicName={topicData[topicSelected].topicName} ip={ip} user={user}/>
          <Footer />
        </div>
      </div>
    </>
  );
}