import { useState, useEffect } from 'react';
import { getTopicItems, topicData, handleVote, getUser, getTopic, getTopics, deleteTopic } from '../api/firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VOTE_DISABLED = true; 

export default function Project({ topicName, ip, user } : { topicName: string; ip: string, user: any }) {
  const [data, setData] = useState<any>([]);
  const [topicListMap, setTopicList] = useState<any>({});

  const debug = () => {
    getTopics().then(async (data: any) => {
      const table:any = {}; 
      const ams = ['Nguyễn Thành Trung', 'Nguyễn Thế Vinh'];
      const deletedArr:any = []; 
      console.log(data?.length);
      data.filter((item: any) => item?.topicName === 'influencer').forEach((item: any) => {
        if (table[item?.title] === undefined)  {
          table[item?.title] = []; 
        }
        table[item?.title].push(item);
        if (ams.includes(item?.title)) {
          deletedArr.push(item);
        }
      })
      console.log(table);
      Object.keys(table).filter((key: string) => table[key].length > 1).forEach((key: string) => {
        table[key].map(async (item: any) => {
          if (item.people === 160 || item.people === 225) {
            await deleteTopic(item?.id);
          }
        })
      })
      // Object.keys(table).filter((key: string) => ams.includes(key?.trim())).forEach((key: string) => {
      //   table[key].map(async (item: any) => {
      //     await deleteTopic(item?.id);
      //   })
      // })
      
      // const nguyenThanhTrung = topicData.influencer[4];
      // const nguyenTheVinh = topicData.influencer[5];
      // await newTopic({...nguyenThanhTrung, people: 161});
      // await newTopic({...nguyenTheVinh, people: 228});
    })
  }

  useEffect(() => debug(), [])

  const getTopicVotes = () => {
    return getTopics().then(topicData => {
      const topicMap: any = {};
      topicData.forEach((item: any) => {
        if(!topicMap[item.topicName]) {
          topicMap[item.topicName] = {};
        }
        topicMap[item.topicName][item.title] = item.people;
      });
      setTopicList(topicMap);
    });
  }

  var currentIndex = 0;

  const addLoading = () => {
    const button:any = document.querySelector('.mfp-content > .mfp-figure > figure > button');
    button.innerHTML = ' <i class="fa fa-spinner fa-spin loading-icon"></i>';
    button.classList?.add('waiting');
  }

  const updateVottedCount = async (title: string) => {
    const voteSpan:any = document.querySelector('.mfp-content > .mfp-figure > figure > figcaption > .mfp-bottom-bar > .mfp-title');
    const updatedTopicData:any = await getTopic(topicName, title);
    voteSpan.innerHTML = `Số người bình chọn: ${updatedTopicData?.people}`;
    getTopicVotes();
  }; 

  const handleGetTopicData = async() => {
    const data = await getTopicItems(topicName);
    setData(data);
    setTimeout(() => {
      handleAnimation();
    }, 4000);
  }

  const updateVoteButtonText = async (title: string) => {
    const button:any = document.querySelector('.mfp-content > .mfp-figure > figure > button');
    const userData:any = await getUser(user?.ip);
    
    var value; 
    if (userData?.votted?.[topicName]?.includes(title)) {
      value = 'Unvote';
    } else {
      value = 'Vote';
    }
    
    button.innerHTML = value; 
  }

  const voteClick = async (title: string) => {
    if (VOTE_DISABLED) {
      return; 
    }

    if (!user) {
      return; 
    }
    addLoading(); 
    await handleVote(user, topicName, title)
    await updateVoteButtonText(title);
    await updateVottedCount(title);
    const userData:any = await getUser(user?.ip);
    if (userData?.votted?.[topicName]?.includes(title)) {
      toast('Bạn đã bỏ phiếu thành công');
    } else {
      toast('Bạn đã rút phiếu thành công');
    }
    document.querySelector('.mfp-content > .mfp-figure > figure > button')?.classList.remove('waiting');
  };

  const handleClick = async (title: string, index: number) => {
    const figure = document.querySelector('.mfp-content > .mfp-figure > figure');
    const voteButton:any = document.createElement('button');
    voteButton?.addEventListener('click', async () => voteClick(title));
    voteButton.className = 'button topic-vote-button';
    if (VOTE_DISABLED) {
      voteButton.className = voteButton.className + ' disabled';
    }
    if (topicData[topicName][index]?.content) {
      const warrar:any = document.createElement('div');
      const contentWrapper:any = document.createElement('p');
      warrar.className = 'warrar';
      contentWrapper.className = 'topic-content-wrapper uppercase';
      contentWrapper.innerHTML = `${topicData[topicName][index]?.content}`;
      figure?.appendChild(warrar);
      figure?.appendChild(contentWrapper);
    }
    currentIndex = index; 
    addHandleClickArrow();
    figure?.appendChild(voteButton);
    updateState(currentIndex);
  }

  const updateState = async (index: number) => {
    const contentWrapper:any = document.querySelector('.topic-content-wrapper');
    const image:any = document.querySelector('.mfp-img');
    if (contentWrapper) {
      contentWrapper.innerHTML = topicData[topicName][index]?.content; 
    }
    image.src = topicData[topicName][index]?.image;
    await addLoading(); 
    await updateVottedCount(topicData[topicName][index]?.title);
    await updateVoteButtonText(topicData[topicName][index]?.title);
    document.querySelector('.mfp-content > .mfp-figure > figure > button')?.classList.remove('waiting');
  }

  const prev = () => {
    if (currentIndex == 0) {
      currentIndex = topicData[topicName].length - 1;
    } else {
      currentIndex -= 1; 
    }
    updateState(currentIndex);
  }

  const next = () => {
    if (currentIndex == topicData[topicName].length - 1) {
      currentIndex = 0;
    } else {
      currentIndex += 1; 
    }
    updateState(currentIndex);
  }

  const addHandleClickArrow = () => {
    document.querySelector('.mfp-arrow.mfp-arrow-left.mfp-prevent-close')?.addEventListener('click', prev)
    document.querySelector('.mfp-arrow.mfp-arrow-right.mfp-prevent-close')?.addEventListener('click', next);
    document.querySelector('.topic-content-wrapper')?.addEventListener('click', next);
    document.querySelector('.mfp-img')?.addEventListener('click', next);
    document.querySelector('.warrar')?.addEventListener('click', next);
  }

  const handleAnimation = () => {
    try {
      $('.project.project-item').removeClass('animated fadeInUp');

      setTimeout(() => {
        $('.project.project-item').removeClass('opacity-0').addClass('animated fadeInUp');
      }, 1);
    } catch {

    }
  }

  const renderTitle = (item: any) => {
    if (item?.mobile_title && window.innerWidth < 768) {
      return item?.mobile_title; 
    }
    
    return item?.title; 
  }

  const renderDialogTitle = (item: any) => {
    return `Số người bình chọn: ${topicListMap?.[topicName]?.[item.title]}`;   
  } 

  useEffect(() => {
    handleGetTopicData();
    getTopicVotes();
    setData(topicData[topicName]);
  }, []);

  useEffect(() => {
    handleAnimation();
  }, [topicName]);

  return (
    <section className="projects">
      <ToastContainer theme={'dark'} toastClassName={'toastify'}/>

      <div className="section-content">
        <div className="project-wrapper projects-carousel js-projects-gallery">
          {topicData?.[topicName]?.map((item: any, index: number) => {
            return (
              <div className="project project-item opacity-0" key={`${item.name}-${index}`}>
                <a href={item?.image} title={renderDialogTitle(item)}  onClick={() => handleClick(item?.title, index)}>
                  <figure>
                    <img alt="" src={item?.image} />
                    <figcaption>
                      <h3 className="project-title">
                        {item?.hintPlace ? item?.hintPlace : renderTitle(item)}
                      </h3>
                      {item?.special && <h3 className="project-title text-gray">
                        {item?.special}
                      </h3>}
                      <h4 className="project-category">
                        {item?.topicName}
                      </h4>
                      <h4 className={'topic-vote'}>{topicListMap?.[topicName]?.[item?.title?.trim()]} Votes</h4>
                    </figcaption>
                  </figure>
                </a>
              </div>
            )
          })}
        </div>
      </div>
   </section>
  ); 
}