import SiteLogo from '../assets/LogoCCA-01.png';
export default function Loader() {
  return (
    <div className="loader">
      <div className="page-lines">
        <div className="container">
          {/* <div className="col-line col-xs-4">
            <div className="line"></div>
            </div>
            <div className="col-line col-xs-4">
            <div className="line"></div>
            </div>
            <div className="col-line col-xs-4">
            <div className="line"></div>
            <div className="line"></div>
          </div> */}
        </div>
      </div>
      <div className="loader-brand"> 
        <img src={SiteLogo} alt="app-logo" className="loader-image"/>
        {/* <div className="sk-folding-cube">
          <div className="sk-cube1 sk-cube"></div>
          <div className="sk-cube2 sk-cube"></div>
          <div className="sk-cube4 sk-cube"></div>
          <div className="sk-cube3 sk-cube"></div>
        </div> */}
      </div>
    </div>
  ); 
}