import { useEffect, useState } from 'react';
import Loader from './components/loader';

import Home from './home';
import AboutUs from './about-us';
import BlogDetails from './blog-details';
import BlogList from './blog';
import Advisor from './advisor';

import { BrowserRouter, useRoutes } from 'react-router-dom';

function Routes() {
  const routes = useRoutes([
    { 
      path: '/', 
      element: <Home /> 
    }, 
    { 
      path: '/ve-chung-toi', 
      element: <AboutUs /> 
    }, 
    {
      path: '/bai-viet', 
      element: <BlogList />
    }, 
    {
      path: '/chi-tiet-bai-viet/:id', 
      element: <BlogDetails />
    }, 
    {
      path: '/ban-co-van',
      element: <Advisor />
    }
  ]);
  return routes;
}


function App() {
  const [isRenderLayout, setIsRenderLayout] = useState(false);
  useEffect(() => {
    var interval:any; 
    const urls:any = [
      '/js/jquery.min.js', 
      '/js/bootstrap.min.js', 
      '/js/smoothscroll.js', 
      '/js/jquery.validate.min.js', 
      '/js/wow.min.js', 
      '/js/jquery.stellar.min.js', 
      '/js/jquery.magnific-popup.js', 
      '/js/owl.carousel.min.js',
      '/js/rev-slider/jquery.themepunch.tools.min.js', 
      '/js/rev-slider/jquery.themepunch.revolution.min.js', 
      '/js/rev-slider/revolution.extension.actions.min.js', 
      '/js/rev-slider/revolution.extension.carousel.min.js', 
      '/js/rev-slider/revolution.extension.kenburn.min.js', 
      '/js/rev-slider/revolution.extension.layeranimation.min.js', 
      '/js/rev-slider/revolution.extension.migration.min.js', 
      '/js/rev-slider/revolution.extension.navigation.min.js', 
      '/js/rev-slider/revolution.extension.parallax.min.js', 
      '/js/rev-slider/revolution.extension.slideanims.min.js',  
      '/js/rev-slider/revolution.extension.video.min.js', 
      '/js/interface.js'
    ];

    const method = async (i: number) => {
      if (!urls.length) return; 
      if (i >= urls.length) {
        interval = setInterval(() => {
          const owls: any = document.getElementsByClassName('owl-wrapper');
          if (owls.length) {
            if (owls[0].style.display === 'inline-flex') {
              clearInterval(interval);
              return; 
            }
            console.log('adding');
            owls[0].style.display = 'inline-flex';
          }
        }, 300);
        return; 
      }
      var item = urls[i];
      var script = document.createElement('script');
      script.src = item; 
      script.async = false;
      document.body.appendChild(script);
      
      setTimeout(() => method(i + 1), 5);
    }

    method(0);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    var interval:any; 
    var urls:any = [
      "https://fonts.googleapis.com/css?family=Oswald:300,400,700", 
      "https://fonts.googleapis.com/css?family=Montserrat:400,700", 
      "/css/bootstrap.min.css", 
      "/css/font-awesome.min.css", 
      "/css/animate.css", 
      "/css/hover.css", 
      "/css/magnific-popup.css", 
      "/css/owl.transitions.css", 
      "/css/owl.transitions.css", 
      "/css/settings.css", 
      "/css/layers.css", 
      "/css/style.css", 
      'https://unpkg.com/boxicons@2.1.1/css/boxicons.min.css', 
      "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css", 
      "https://fonts.googleapis.com", 
      "https://fonts.gstatic.com", 
      "https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;1,100&display=swap", 
    ]; 
    const media = ["css/style.css"];
    const preconnect = ["https://fonts.googleapis.com", "https://fonts.gstatic.com"]; 
    const crossOrigin = ["https://fonts.gstatic.com"];
    const method = (i: number) => {
      if (i >= urls.length) {
        setIsRenderLayout(true);
        return; 
      }
      var item = urls[i];
      var link = document.createElement('link');
      link.href = item; 
      link.rel = 'stylesheet';
      if (media.includes(item)) {
        link.media = 'screen';
      }
      if (preconnect.includes(item)) {
        link.rel = 'preconnect';
      }
      if (crossOrigin.includes(item)) {
        link.crossOrigin = 'true'; 
      }
      document.body.appendChild(link);
      setTimeout(() => method(i + 1), 0.5);
    }
    method(0);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const loader:any = document.querySelector('.loader');
      if (loader) {
        loader.style.display = 'none';
      }
  }, 2000)}, []);

  return (
    <>
      <Loader />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </>
  );
}

export default App;
