import { useEffect, useState } from 'react';
import { 
  infulencer as infulencerData, 
  coins as coinsData, 
  events as eventsData, 
  community as comunityData, 
  recentPosts } from './data';
import { useParams } from 'react-router-dom';
import Header from './components/header';
import Footer from "./components/footer";

const Index = () => {
  const [isRenderLayout, setIsRenderLayout] = useState(false);
  let { id: topic } = useParams();

  useEffect(() => {
    var interval:any;
    var urls:any = [
      "https://fonts.googleapis.com/css?family=Oswald:300,400,700",
      "https://fonts.googleapis.com/css?family=Montserrat:400,700",
      "/css/bootstrap.min.css",
      "/css/font-awesome.min.css",
      "/css/animate.css",
      "/css/hover.css",
      "/css/magnific-popup.css",
      "/css/owl.transitions.css",
      "/css/owl.transitions.css",
      "/css/settings.css",
      "/css/layers.css",
      "/css/style.css",
      'https://unpkg.com/boxicons@2.1.1/css/boxicons.min.css',
      "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css",
      "https://fonts.googleapis.com",
      "https://fonts.gstatic.com",
      "https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;1,100&display=swap",
    ];
    const media = ["css/style.css"];
    const preconnect = ["https://fonts.googleapis.com", "https://fonts.gstatic.com"];
    const crossOrigin = ["https://fonts.gstatic.com"];
    const method = (i: number) => {
      if (i >= urls.length) {
        setIsRenderLayout(true);
        return;
      }
      var item = urls[i];
      var link = document.createElement('link');
      link.href = item;
      link.rel = 'stylesheet';
      if (media.includes(item)) {
        link.media = 'screen';
      }
      if (preconnect.includes(item)) {
        link.rel = 'preconnect';
      }
      if (crossOrigin.includes(item)) {
        link.crossOrigin = 'true';
      }
      document.body.appendChild(link);
      setTimeout(() => method(i + 1), 0.5);
    }
    method(0);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    var interval:any;
    const urls:any = [
      '/js/jquery.min.js',
      '/js/bootstrap.min.js',
      '/js/smoothscroll.js',
      '/js/jquery.validate.min.js',
      '/js/wow.min.js',
      '/js/jquery.stellar.min.js',
      '/js/jquery.magnific-popup.js',
      '/js/owl.carousel.min.js',
      '/js/rev-slider/jquery.themepunch.tools.min.js',
      '/js/rev-slider/jquery.themepunch.revolution.min.js',
      '/js/rev-slider/revolution.extension.actions.min.js',
      '/js/rev-slider/revolution.extension.carousel.min.js',
      '/js/rev-slider/revolution.extension.kenburn.min.js',
      '/js/rev-slider/revolution.extension.layeranimation.min.js',
      '/js/rev-slider/revolution.extension.migration.min.js',
      '/js/rev-slider/revolution.extension.navigation.min.js',
      '/js/rev-slider/revolution.extension.parallax.min.js',
      '/js/rev-slider/revolution.extension.slideanims.min.js',
      '/js/rev-slider/revolution.extension.video.min.js',
      '/js/interface.js'
    ];

    const method = async (i: number) => {
      if (!urls.length) return;
      if (i >= urls.length) {
        interval = setInterval(() => {
          const owls: any = document.getElementsByClassName('owl-wrapper');
          if (owls.length) {
            if (owls[0].style.display === 'inline-flex') {
              clearInterval(interval);
              return;
            }
            console.log('adding');
            owls[0].style.display = 'inline-flex';
          }
        }, 300);
        return;
      }
      var item = urls[i];
      var script = document.createElement('script');
      script.src = item;
      script.async = false;
      document.body.appendChild(script);

      setTimeout(() => method(i + 1), 5);
    }

    method(0);
    return () => clearInterval(interval);
  }, []);


  const renderDetails = () => {
    switch (topic) {
      case 'nhan-vat-cua-nam': 
        return infulencerData;
      case 'du-an-cua-nam': 
        return coinsData; 
      case 'su-kien-cua-nam': 
        return eventsData; 
      case 'cong-dong-cua-nam': 
        return comunityData; 
    }
  }

  return isRenderLayout ? (
    <div className={'main-layout'}>
      <Header enableBrandPanel={true} enableVerticalPanel={false} />

      <div className="layout">
        <div className="content">
          <section className="blog-details">
            <div className="container">
              <div className="row">
                <div className="col-primary col-md-8">
                  <article className="app-post">
                    {renderDetails()}
                  </article>
                </div>
                <div className="col-secondary col-md-4">
                  <div className="widget widget_recent_post">
                    <h3 className="widget-title">Recent Post</h3>
                    {recentPosts.map((item: any, index: number) => (
                      <article className="recent-post" key={`tt-${index}`}>
                        <div className="recent-post-thumbnail">
                          <a onClick={() => window.open(item.slug)}><img alt="" src={item.image} className="wp-post-image" /></a>
                        </div>
                        <div className="recent-post-body">
                          <h4 className="recent-post-title">
                            <a onClick={() => window.open(item.slug)}>{item.title}</a>
                          </h4>
                        </div>
                      </article>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <Footer />

    </div>
  ) : <></>;
}

export default Index;