// @ts-nocheck

import 'react-html5video/dist/styles.css';

export default function About(){
  return (
    <section id="about" className="about section">
      <div className="container">
        <header className="section-header">
          <h2 className="section-title">Giới thiệu <br/><br/><span className="text-primary">Crypto Choice Awards</span></h2>
        </header>
          <div className="section-content about">
            <div className="row-base row">
              <div className="col-base col-sm-6 col-md-4">
                <h2 className="col-about-title sub-title">Một năm <br/> đáng nhớ</h2>
                <div className="col-about-info">
                  <p>Với chủ đề “Bước ra ánh sáng”, đội ngũ Coin68 muốn xem đây là sự tri ân đến những gì mà tất cả chúng ta - cộng đồng Blockchain và Cryptocurrency đã đạt được sau một “mùa đông dài” lạnh giá.
                      Thị trường tiền mã hóa đã trưởng thành và phát triển mạnh mẽ đến mức không một ai - kể cả những quốc gia quyền lực nhất hay những thể chế tài chính lâu đời nhất - có thể lờ đi được nữa.
                  </p>
                </div>
              </div>
              <div className="col-base col-about-spec col-sm-6 col-md-4">
                <h2 className="col-about-title sub-title">Hạng mục <br/> bình chọn</h2>
                <div className="service-item">
                  <i className='bx bxs-user about-icon'></i>
                  <h4 className="app-font">NHÂN VẬT CỦA NĂM</h4>
                </div>
                <div className="service-item">
                  <i className='bx bxs-coin about-icon'></i>
                  <h4 className="app-font">SỰ KIỆN CỦA NĂM</h4>
                </div>
                <div className="service-item">
                  <i className='bx bxl-meta a about-icon'></i>
                  <h4 className="app-font">DỰ ÁN CỦA NĂM</h4>
                </div>
                <div className="service-item">
                  <i className='bx bxs-group about-icon'></i>
                  <h4 className="app-font">CỘNG ĐỒNG CỦA NĂM</h4>
                </div>
              </div>
              <div className="clearfix visible-sm"></div>
              <div className="col-base col-about-img col-sm-6 col-md-4">
                <h2 className="col-about-title sub-title">TIÊU CHÍ <br/> CHẤM ĐIỂM</h2>
                <div className="col-about-info">
                  <p>Giải thưởng sẽ được trao dựa trên kết quả bình chọn từ Cộng đồng, Hội đồng Cố vấn và Ban Biên tập Coin68 với thể lệ như sau:</p>
                  <p>- Cộng đồng bình chọn: 50% số điểm</p>
                  <p>- Hội đồng Cố vấn: 30% số điểm</p>
                  <p>- Ban Biên tập: 20% số điểm</p>
                </div>
              </div>
            </div>
            <div className="intro-video-content">
              <div style={{width:'100%',height:'0px',position:'relative',paddingBottom:'56.250%'}}>
                <iframe src="https://streamable.com/e/qamor5?autoplay=1" frameBorder="0" width="100%"
                        height="100%" allowFullScreen allow="autoplay"
                        style={{width:'100%',height:'100%',position:'absolute',left:0,top:0,overflow:'hidden'}}>
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}