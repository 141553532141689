import Slide1Back from '../assets/slide1.png';
import Slide2Back from '../assets/slide2.png';
import Slide3Back from '../assets/slide3.png';
import Slide4Back from '../assets/slide4.png';

import Slide1BackMobile from '../assets/mobile_slide/slide1.png';
import Slide2BackMobile from '../assets/mobile_slide/slide2.png';
import Slide3BackMobile from '../assets/mobile_slide/slide3.png';
import Slide4BackMobile from '../assets/mobile_slide/slide4.png';

export default function Main() {
  const renderSlideImage = (desktop: any, mobile: any) => {
    if (window.innerWidth < 768) {
      return mobile;
    }
    return desktop;
  }

  return (
    <main className="main">

      <div className="arrow-left"></div>
      <div className="arrow-right"></div>

      {/* <!-- Start revolution slider --> */}

      <div className="rev_slider_wrapper">
        <div id="rev_slider" className="rev_slider fullscreenbanner">
          <ul>
            <li>
              {/* <!-- Main image--> */}
              <img src={renderSlideImage(Slide1Back, Slide1BackMobile)}  alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" />

              {/* <!-- Layer 3 --> */}

              <div className="tp-caption vote-content"
                data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" 
                data-y="['middle','middle','middle','middle']" data-voffset="['195','215']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:300;e:Power1.easeInOut;"
                data-style_hover="c:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);"
                data-transform_in="y:50px;opacity:0;s:1500;e:Power4.easeInOut;" 
                data-transform_out="y:[175%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                data-mask_out="x:inherit;y:inherit;" 
                data-start="1500" 
                data-splitin="none" 
                data-splitout="none" 
                data-responsive_offset="on"><a href="#about" className="btn js-target-scroll btn-vote-now">Vote now<i className="icon-next"></i></a>
              </div>
            </li>
            <li>

              {/* <!-- Main image--> */}

              <img src={renderSlideImage(Slide2Back, Slide2BackMobile)}  alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" />

              <div className="tp-caption"
                data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" 
                data-y="['middle','middle','middle','middle']" data-voffset="['195','215']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:300;e:Power1.easeInOut;"
                data-style_hover="c:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);"
                data-transform_in="y:50px;opacity:0;s:1500;e:Power4.easeInOut;" 
                data-transform_out="y:[175%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                data-mask_out="x:inherit;y:inherit;" 
                data-start="1500" 
                data-splitin="none" 
                data-splitout="none" 
                data-responsive_offset="on"><a href="#about" className="btn js-target-scroll btn-vote-now">Vote now<i className="icon-next"></i></a>
              </div>
            </li>
            <li>

              {/* <!-- Main image--> */}

              <img src={renderSlideImage(Slide3Back, Slide3BackMobile)}  alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" />

              <div className="tp-caption"
                data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" 
                data-y="['middle','middle','middle','middle']" data-voffset="['195','215']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:300;e:Power1.easeInOut;"
                data-style_hover="c:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);"
                data-transform_in="y:50px;opacity:0;s:1500;e:Power4.easeInOut;" 
                data-transform_out="y:[175%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                data-mask_out="x:inherit;y:inherit;" 
                data-start="1500" 
                data-splitin="none" 
                data-splitout="none" 
                data-responsive_offset="on"><a href="#about" className="btn js-target-scroll btn-vote-now">Vote now<i className="icon-next"></i></a>
              </div>
            </li>
            <li>

              {/* <!-- Main image--> */}

              <img src={renderSlideImage(Slide4Back, Slide4BackMobile)}  alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" />

              <div className="tp-caption"
                data-x="['right','right','right','right']" data-hoffset="['0','0','0','0']" 
                data-y="['middle','middle','middle','middle']" data-voffset="['195','215']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-transform_idle="o:1;"
                data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:300;e:Power1.easeInOut;"
                data-style_hover="c:rgba(255, 255, 255, 1.00);bc:rgba(255, 255, 255, 1.00);"
                data-transform_in="y:50px;opacity:0;s:1500;e:Power4.easeInOut;" 
                data-transform_out="y:[175%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" 
                data-mask_out="x:inherit;y:inherit;" 
                data-start="1500" 
                data-splitin="none" 
                data-splitout="none" 
                data-responsive_offset="on"><a href="#about" className="btn js-target-scroll btn-vote-now">Vote now<i className="icon-next"></i></a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </main>
  );
}
