import SiteLogo from '../assets/LogoCCA-01.png';

export default function Header(
    { enableBrandPanel, enableVerticalPanel }: { enableVerticalPanel: boolean, enableBrandPanel: boolean }
) {
    const handleScrollIntoView = (sectionRef: string) => {
        document.getElementById(sectionRef)?.scrollIntoView();
    }

    const currentPath = window.location.pathname;

    return (
        <header id="top" className="header-home">
            {enableBrandPanel && (
                <div className="brand-panel">
                    <a href="/" className="brand js-target-scroll clickable">
                        <img src={SiteLogo} className="header-logo"/>
                    </a>
                </div>
            )}
            {enableVerticalPanel && (
                <>
                    <div className="vertical-panel"></div>
                    <div className="vertical-panel-content">
                        <div className="vertical-panel-info">
                            <div className="vertical-panel-title">Crypto Choice Awards</div>
                            <div className="line"></div>
                        </div>
                        <ul className="social-list">
                            <li><a href="https://coin68.com/" className="fa fa-globe"></a></li>
                            <li><a href="https://www.youtube.com/c/Coin68TV" className="fa fa-youtube-play"></a></li>
                            <li><a href="https://twitter.com/coin68" className="fa fa-twitter"></a></li>
                            <li><a href="https://www.facebook.com/tintuccoin68/" className="fa fa-facebook"></a></li>
                        </ul>
                    </div>
                </>
            )}

            {/* <!-- Navigation Desctop --> */}

            <nav className="navbar-desctop visible-md visible-lg">
                <div className="container">
                    <a className="clickable brand js-target-scroll" onClick={() => handleScrollIntoView('#')}>
                        <img src={SiteLogo} className="header-logo"/>
                    </a>
                    <ul className="navbar-desctop-menu">
                        <li className={`clickable ${currentPath === '/' ? 'active' : ''}`}>
                            <a href="/"><h4 className={'navbar-route'}>Trang chủ</h4></a>
                        </li>
                        <li className={`clickable ${currentPath === '/bai-viet' ? 'active' : ''}`}>
                            <a href="/bai-viet"><h4 className={'navbar-route'}>Các đề cử</h4></a>
                        </li>
                        <li className={`clickable ${currentPath === '/ban-co-van' ? 'active' : ''}`}>
                            <a href="/ban-co-van"><h4 className={'navbar-route'}>Ban cố vấn</h4></a>
                        </li>
                        <li className={`clickable ${currentPath === '/ve-chung-toi' ? 'active' : ''}`}>
                            <a href="/ve-chung-toi"><h4 className={'navbar-route'}>Về Chúng Tôi</h4></a>
                        </li>
                    </ul>
                </div>
            </nav>

            {/* <!-- Navigation Mobile --> */}

            <nav className="navbar-mobile">
                <a href="/" className="brand js-target-scroll clickable">
                    <img src={SiteLogo} className="header-logo"/>
                </a>

                {/* <!-- Navbar Collapse --> */}

                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                        data-target="#navbar-mobile">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbar-mobile">
                    <ul className="navbar-nav-mobile">
                        <li className={`clickable ${currentPath === '/' ? 'active' : ''}`}>
                            <a href="/"><h4 className={'navbar-route'}>Trang chủ</h4></a>
                        </li>
                        <li className={`clickable ${currentPath === '/bai-viet' ? 'active' : ''}`}>
                            <a href="/bai-viet"><h4 className={'navbar-route'}>Các đề cử</h4></a>
                        </li>
                        <li className={`clickable ${currentPath === '/ban-co-van' ? 'active' : ''}`}>
                            <a href="/ban-co-van"><h4 className={'navbar-route'}>Ban cố vấn</h4></a>
                        </li>
                        <li className={`clickable ${currentPath === '/ve-chung-toi' ? 'active' : ''}`}>
                            <a href="/ve-chung-toi"><h4 className={'navbar-route'}>Về chúng tôi</h4></a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}