import {recentPosts as postList} from './data';
import Header from "./components/header";
import Footer from "./components/footer";

const Index = () => {

    setTimeout(() => {
        $('.blog').removeClass('opacity-0').addClass('animated fadeInUp');
    }, 2000);

    return (
        <div className={'main-layout'}>
            <Header enableBrandPanel={true} enableVerticalPanel={false}/>

            <div className="layout">
                <div className="content">
                    <section className="blog-list">
                        <div className="container">
                            {postList?.map((item: any, index: number) => (
                                <article className="blog opacity-0" key={`blog-list-item-${index}`}>
                                    <div className="row">
                                        <div className="blog-thumbnail col-md-8">
                                            <div className="blog-thumbnail-bg col-md-8 visible-md visible-lg"><img
                                                alt="" className="img-responsive" src={item?.image}/></div>
                                            <div className="blog-thumbnail-img visible-xs visible-sm">
                                                <img alt=""
                                                     className="img-responsive"
                                                     src={item?.image}/>
                                            </div>
                                        </div>
                                        <div className="blog-info col-md-4">
                                            <h3 className="blog-title">
                                                <a href={item?.slug}>{item?.title}</a>
                                            </h3>
                                            <p>{item?.title}</p>
                                            <div className="blog-meta">
                                                <div className="author">
                                                    {/*by <a href="#">Coin 68</a>*/}
                                                </div>
                                            </div>
                                            <div className="text-right"><a href={item?.slug} className="read-more">Read
                                                more</a></div>
                                        </div>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </section>
                </div>
            </div>

            <Footer/>
        </div>
    )
}

export default Index; 