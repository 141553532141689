import Footer from './components/footer';
import Header from "./components/header";

export default function AboutUsPage() {
  return (
    <div className="layout">
      <Header enableBrandPanel={true} enableVerticalPanel={false} />
    <main className="main main-inner main-blog bg-blog" data-stellar-background-ratio="0.6">
      <div className="container">
        <header className="main-header">
          <h1>Về chúng tôi</h1>
        </header>
      </div>

      <div className="page-lines">
        <div className="container">
          <div className="col-line col-xs-4">
            <div className="line"></div>
          </div>
          <div className="col-line col-xs-4">
            <div className="line"></div>
          </div>
          <div className="col-line col-xs-4">
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>
    </main>

    <div className="content">   
    
      {/* <!-- Blog Details --> */}

      <section className="blog-details">
        <div className="container">
          <div className="row">
            <div className="col-primary col-md-12">
              <article className="post about-us-content">
                <h2>1. Giới thiệu chung</h2> 
                <p>2021 là một năm với đầy ắp những biến động đối với thị trường tiền mã hóa, hơn đâu hết, tại Việt Nam - một trong những quốc gia tiếp cận mạnh mẽ nhất với tiền mã hóa, chúng ta càng cảm nhận rõ những chuyển động của thị trường hơn.</p>
                <p>Crypto Choice Awards là một sự kiện được tổ chức bởi Coin68 nhằm nhìn lại bức tranh toàn cảnh ngành công nghiệp Blockchain & Cryptocurrency một năm đã qua, đồng thời cũng để ghi nhận sự đóng góp của những cá nhân, tập thể trong hành trình phát triển chung của thị trường.</p>
                <p>Với chủ đề “Bước ra ánh sáng”, đội ngũ Coin68 muốn xem đây là sự tri ân đến những gì mà tất cả chúng ta - cộng đồng Blockchain và Cryptocurrency đã đạt được sau một “mùa đông dài” lạnh giá. Thị trường tiền mã hóa đã trưởng thành và phát triển mạnh mẽ đến mức không một ai - kể cả những quốc gia quyền lực nhất hay những thể chế tài chính lâu đời nhất có thể lờ đi được nữa.</p>
                <p>Tất cả đều muốn trở thành một phần của bức tranh.</p>
                <p>Và chúng ta mới chỉ bắt đầu.</p>
                <h2>2. Thời gian</h2>
                <p>15/02/2022 - 03/03/2022</p>
                <h2>3. Hạng mục & Tiêu chí bình chọn</h2>
                <br/>
                <br/>
                <h3>Nhân vật của năm</h3>
                <br/>
                <p>Nhân vật của năm là hạng mục nhằm vinh danh những cá nhân xuất sắc, dẫn đầu sự phát triển của thị trường tiền mã hóa thông qua những cống hiến của họ cho một dự án nhất định, hoặc một mảng lớn của thị trường.</p>
                <p>12 nhân vật được Coin68 lựa chọn là những nhân vật/ tổ chức kiến tạo xu thế, thúc đẩy thị trường tiền mã hóa phát triển không ngừng, thậm chí là trong những thời khắc khó khăn nhất.</p>
                <p>Hôm nay, hãy cùng Coin68 vinh danh họ.</p>
                <h3>Sự kiện của năm</h3>
                <br/>
                <p>Con người là sinh vật cảm xúc. Còn thị trường tiền mã hóa có thể đưa chúng ta đến tận cùng của cảm xúc, đôi khi, đó là một phần tạo nên sức hút của thị trường này. </p>
                <p>Hãy cùng Coin68 nhìn lại 12 sự kiện đáng nhất trong suốt 365 ngày vừa qua.</p>
                <h3>Cộng đồng của năm</h3>
                <br/>
                <p>Có lẽ chính tại thị trường tiền mã hóa, chúng ta mới thấy sức mạnh của một cộng đồng đông đảo và đoàn kết mạnh mẽ đến mức nào. Không chỉ là một safe zone - vùng an toàn để chia sẻ kiến thức, cộng đồng còn là những người đánh giá công tâm nhất về một dự án, một xu hướng  hay một phong trào…</p>
                <p>Nếu được giới thiệu cho một người mới tham gia thị trường tiền mã hóa, bạn sẽ giới thiệu cộng đồng nào đến họ?</p>
                <h3>Dự án của năm</h3>
                <br/>
                <p>Sau rất nhiều những kiến thức, nhiều giờ phân tích, nghiên cứu tài liệu, thảo luận với cộng đồng, chúng ta vẫn cần một cái tên cụ thể để hiện thực hóa “tầm nhìn” đối với thị trường tiền mã hóa.</p>
                <p>Đâu là “trái ngọt” của bạn trong năm 2021?</p>
                <p style={{color: 'white'}}><strong>Tất cả các giải thưởng sẽ được trao dựa trên kết quả bình chọn từ cộng đồng, Hội đồng Cố vấn và Ban Biên tập với thể lệ như sau:</strong></p>
                <p>- Cộng đồng bình chọn: 50% số điểm</p>
                <p>- Hội đồng Cố vấn: 30% số điểm</p>
                <p>- Ban Biên tập: 20% số điểm</p>
                <h2>Thể thức bình chọn</h2>
                <p><img src="/thethucbinhchon.png" /></p>
                <br />
                <h3>Về trọng số bình chọn:</h3>
                <p><strong>Cộng đồng: 50%</strong></p>
                <p><strong>Ban Cố vấn: 30%</strong></p>
                <p><strong>Ban Biên tập: 20%</strong></p>

                <p>Con số này được hiểu như thế nào?</p>

                <p>Kết quả bình chọn cuối cùng sẽ được quy về điểm số: Nhân vật/Sự kiện/Dự án/Cộng đồng được nhiều bình chọn nhất sẽ có 12 điểm, ngược lại là 1 điểm</p>

                <p>Số điểm cuối cùng của một đề cử sẽ là: (Điểm từ Cộng đồng bình chọn)x50% + (Điểm từ Ban Cố vấn bình chọn)x30% + Điểm từ Ban Biên tập bình chọn)x20%</p>

                <p> Nhân vật/Sự kiện/Dự án/Cộng đồng có số điểm cao nhất sẽ được vinh danh ở hạng mục Đề cử của năm.</p>

                <ul>
                  <li><blockquote>Về phía Cộng đồng: Một hạng mục có thể được chọn nhiều đề cử, tuy nhiên, mỗi người chỉ được bình chọn 01 lần trong suốt thời gian diễn ra sự kiện.</blockquote></li>
                  <li><blockquote>Về phía Ban Cố vấn, trong trường hợp không có bất kỳ lựa chọn nào giống nhau giữa các thành viên Ban Cố vấn, mọi lựa chọn được Ban cố vấn bỏ phiếu đều sẽ nhận được số điểm tối đa (12), các lựa chọn khác sẽ đều sẽ được nhận số điểm ngay sau (11)</blockquote></li>
                  <li><blockquote> Về phía Ban Biên tập, sẽ chỉ có 1 đề cử được lựa chọn tại mỗi hạng mục có được số điểm tối đa (12), các lựa chọn khác sẽ đều sẽ được nhận số điểm ngay sau (11)</blockquote></li>
                </ul>
                <h2>Cách thức bình chọn</h2>
                <br/>
                <p>Bấm vào nút Bình chọn tại ảnh đại diện đề cử khi cổng bình chọn được mở.</p>
                <p>Một hạng mục có thể được chọn nhiều đề cử.</p>
                <p>Một tài khoản chỉ được bình chọn 01 lần trong suốt thời gian diễn ra sự kiện.</p>
                <h2>Quy định chung</h2>
                <br/>
                <p>Coin68 là đơn vị tổ chức, sở hữu trang web 2021.coin68.vn và chịu trách nhiệm thực hiện chương trình.</p>
                <p>Nếu có bất kỳ thay đổi nào về thể lệ cuộc thi, Ban Tổ Chức có quyền lợi thông báo trước 01 ngày tới tất cả đối tượng tham gia giải thưởng trên trang web chính thức.</p>
                <p>Trường hợp bất khả kháng (do thiên tai, khủng bố tấn công máy chủ làm thất thoát dữ liệu đăng ký của độc giả hoặc do nguyên nhân khách quan dẫn đến hệ thống bị lỗi kỹ thuật...), Ban Tổ Chức giữ quyền quyết định thay đổi hoặc hủy bỏ kết quả mà không chịu bất kỳ trách nhiệm nào. Ban Tổ Chức sẽ thông báo với người đại diện trong thời gian sớm nhất.</p>
                <p>Trong trường hợp phát sinh tranh chấp, khiếu nại hoặc phát hiện các dấu hiệu gian lận của người tham gia bình chọn, Ban Tổ Chức sẽ trực tiếp giải quyết và quyết định của Ban Tổ Chức là kết quả sau cùng.</p>
                <p>Ban Tổ Chức có quyền lợi bảo mật thông tin cá nhân cho người tham bình chọn, không chia sẻ cho bên thứ ba và chỉ sử dụng cho mục đích trao đổi thông tin giữa Ban Tổ Chức và các cá nhân này.</p>
                <p>Mọi thắc mắc về chương trình, vui lòng liên hệ qua email về địa chỉ của Ban tổ chức Contact@coin68.com</p>
              </article>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Footer --> */}

      <Footer />
    </div>
  </div>
  );
}