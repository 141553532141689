import Footer from './components/footer';
import Header from "./components/header";

export default function AdvisorPage() {
  return (
    <div className="layout">
      <Header enableBrandPanel={true} enableVerticalPanel={false} />
    <main className="main main-inner main-blog bg-blog" data-stellar-background-ratio="0.6">
      <div className="container">
        <header className="main-header">
          <h1>Ban cố vấn</h1>
        </header>
      </div>

      <div className="page-lines">
        <div className="container">
          <div className="col-line col-xs-4">
            <div className="line"></div>
          </div>
          <div className="col-line col-xs-4">
            <div className="line"></div>
          </div>
          <div className="col-line col-xs-4">
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>
    </main>

    <div className="content">   
    
      {/* <!-- Blog Details --> */}

      <section className="blog-details">
        <div className="container">
          <div className="row">
            <div className="col-primary col-md-12">
              <article className="post about-us-content">
              <h2>Ban Cố vấn Coin68 Crypto Choice Award 2022</h2>
              <p>Ban Cố vấn của Coin68 Crypto Choice Award là những nhân vật có uy tín với cộng đồng tiền mã hóa tại Việt Nam và trên toàn thế giới. Trong suốt hành trình phát triển của bức tranh blockchain - crypto tại Việt Nam, mỗi thành viên Ban cố vấn đều đã có những đóng góp rất quan trọng để cộng đồng có thể vững mạnh như ngày hôm nay.</p>
              <p>Với sự am hiểu và kiến thức sâu rộng về thị trường, các thành viên Ban Cố vấn sẽ tham gia đánh giá và đưa ra lựa chọn của mình cho 4 đề cử <strong>Coin68 Crypto Choice Award 2022, </strong> bên cạnh ý kiến bình chọn từ Cộng đồng và đề cử của Ban Biên tập.</p>

                <h3><strong>Anh Hoàng Minh Thiện</strong></h3>
                <p><strong>Founder, TradeCoin Vietnam Community</strong></p>

                <p><a href="https://twitter.com/TCVNcommunity" rel="nofollow">https://twitter.com/TCVNcommunity</a></p>
                <p><a href="https://www.youtube.com/c/HMThienTCVN" rel="nofollow">https://www.youtube.com/c/HMThienTCVN</a></p>

                <p><img src="/advisor/0.png" /></p>

                <p>Anh Hoàng Minh Thiện là một trong những gương mặt quen thuộc đối với cộng đồng tiền mã hóa ở Việt Nam. Là người sáng lập của TradeCoin Việt Nam - cộng đồng crypto đông đảo nhất tại Việt Nam với hơn 200.000 thành viên - anh Thiện là một trong những người đầu tiên xây dựng cộng đồng về blockchain - crypto tại Việt Nam, khi khái niệm tiền mã hóa/tiền điện tử còn rất mơ hồ.</p>

                <p>Gắn bó với sự phát triển của thị trường tiền mã hóa Việt Nam từ những ngày đầu, câu chuyện của anh Thiện đi liền với những thăng trầm của thị trường. Thành công nhiều, thất bại cũng không ít. Những trải nghiệm quý giá đó đã giúp anh Hoàng Minh Thiện xây dựng và phát triển TradeCoin Việt Nam nói riêng và cộng đồng tiền mã hóa Việt Nam nói chung phát triển bền vững.</p>

                <h3><strong>Anh Thuan Lam</strong></h3>
                <p><strong>Founder, ThuanCapital</strong></p>

                <p><a href="https://twitter.com/ThuanCapital" rel="nofollow">https://twitter.com/ThuanCapital</a></p>
                <p><a href="https://www.youtube.com/c/thuancapitalanalytics" rel="nofollow">https://www.youtube.com/c/thuancapitalanalytics</a></p>

                <p><img src="/advisor/1.png" /></p>

                <p>Anh Thuan Lam (ThuanCapital) được cộng đồng tiền mã hóa Việt Nam biết đến rộng rãi thông qua kênh Youtube thường xuyên cập nhật thị trường, với mật độ thông tin được cập nhật liên tục và theo kịp từng nhịp thở của thị trường. Thế nên, anh Thuan được nhiều người xem là cổng thông tin cập nhật cực kỳ hữu ích dành cho những nhà đầu tư tiền mã hóa.</p>

                <p>Hiện tại kênh youtube ThuanCapical Analytics của anh đã đạt hơn 150.000 người đăng ký. Bên cạnh đó, các kênh thông tin như website và social của cộng đồng ThuanCapical cũng là cổng thông tin đáng tin cậy của cộng đồng tiền mã hóa tại Việt Nam </p>


                <h3><strong>Anh Victor Tran</strong></h3>
                <p><strong>Co-Founder & CEO, Kyber Network</strong></p>

                <p><a href="https://twitter.com/vutran54" rel="nofollow">https://twitter.com/vutran54</a></p>
                <p><a href="https://kyber.network/" rel="nofollow">https://kyber.network/</a></p>
                <p><img src="/advisor/2.png" /></p>

                <p>Anh Victor Trần (Trần Huy Vũ) vốn là gương mặt không hề xa lạ với với giới startup Việt Nam và châu Á khi anh là đồng sáng lập & CTO của Kyber Network, cùng với anh Lưu Thế Lợi. Năm 2018, anh Victor Trần đã trở thành một trong 30 nhân vật dưới 30 tuổi nổi bật nhất châu Á của Forbes (Forbes 30under30 Asia)</p>
                <p>Kyber Network gây chú ý khi gọi vốn được hơn 200.000 Ethereum, tương đương 52 triệu USD, từ hơn 21.000 nhà đầu tư trong đợt chào bán token vào tháng 9/2017, lọt top 10 startup trên toàn thế giới vào thời điểm đó.</p>
                <p>Đây là dự án đầu tiên định nghĩa khái niệm “Dex Aggregator” và WBTC trong thị trường DeFi, đồng thời có thể tích hợp được vào dApp, từ đó giúp cho người dùng có thể thực hiện giao dịch cũng như chuyển đổi (swap) giữa những loại tiền kỹ thuật số trên nền tảng Kyber với tốc độ tức thì. Năm 2019, Kyber Network đã trở thành Sàn giao dịch phi tập trung Top 1 thế giới về khối lượng giao dịch 24h. </p>
                <p>Mới đây, anh Victor Trần đã trở thành CEO mới của Kyber Network, hứa hẹn sẽ tiếp tục dẫn dắt Kyber Network mang DeFi và các ứng dụng của tiền mã hóa đến với đông đảo người dùng hơn trong tương lai.</p>

                <h3><strong>Anh Thuat Nguyen</strong></h3>
                <p><strong>Founder & CEO, Coin68 & Kyros Ventures</strong></p>

                <p><a href="https://twitter.com/Zane_Kyros" rel="nofollow">https://twitter.com/Zane_Kyros</a></p>
                <p><a href="https://www.youtube.com/c/Coin68TV" rel="nofollow">https://www.youtube.com/c/Coin68TV</a></p>

                <p><img src="/advisor/4.png" /></p>
                <p>Anh Thuat Nguyen là CEO và Founder của Coin68 News Media và Kyros Ventures.</p>
                <p>Kyros Ventures là một trong những quỹ đầu tư mạo hiểm tiên phong có tầm ảnh hưởng lớn nhất trong lĩnh vực blockchain Việt Nam và Đông Nam Á. Được xây dựng trên nền tảng vững chắc của hệ sinh thái Coin68, Kyros Ventures đã và đang ươm tạo nhiều dự án blockchain giai đoạn đầu tiềm năng. Mạng lưới của Kyros đang trực tiếp kết nối với hơn 100,000 nhà đầu tư và nhà giao dịch tiền mã hóa.</p>
              </article>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Footer --> */}

      <Footer />
    </div>
  </div>
  );
}