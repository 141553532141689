import SiteLogo from '../assets/LogoCCA-01.png';

export default function Footer() {
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <div className="row-base flex-row row bottom-brand">
          <div className="col-base text-left-md col-md-4">
            <a href="https://2021.coin68.com/" className="brand">
              <img src={SiteLogo} className="footer-logo" />
            </a>
          </div>
          <div className="text-center-md col-base col-md-4">
          </div>
          <div className="text-right-md col-base col-md-4 ">
            Copyright © 2022 by Coin68 - All Rights Reserved
          </div>
        </div>
    </div>
  </footer>
  );
}