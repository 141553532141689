const infulencer = (
  <div>
      <h3><strong>12 nhân vật có sức ảnh hưởng nhất trong thị trường tiền điện tử nhất trong năm 2021</strong></h3>
      <br/>
      <br/>
      <br/>
      <p><img src="/blog/infulencer/title-image.png" /></p>

      <p>Trong năm 2021 có những nhân vật đóng góp đặc biệt làm phát triển thị trường tiền điện tử. Hãy cùng Coin68 Crypto Choice Awards 2022 điểm qua những nhân vật này.</p>

      <p><img src="/blog/infulencer/1.png" /></p>

      <h3><strong>Changpeng Zhao - CZ</strong></h3>
      <br/>
      <p><>CEO/Nhà sáng lập</></p>
      <p><>Sàn giao dịch tiền mã hóa Binance</></p>
      <p><>Năm sinh: 1977</></p>
      <p><>Quốc tịch: Canada</></p>

      <p>Changpeng Zhao - hay còn gọi tắt với nickname CZ hoặc việt hóa là Triệu Trường Bằng - được biết đến như một Chief Executive Officer và là một Founder của sàn giao dịch tiền điện tử lớn nhất hiện tại Binance.</p>

      <p>Changpeng Zhao biết đến Bitcoin vào năm 2013 qua một người bạn của ông. CZ ông tô ra hứng và dành rất nhiều thời gian để nghiên cứu. Vào năm 2014, CZ gia nhập vào OKCoin - Sàn giao dịch Bitcoin lớn nhất Trung Quốc vào thời điểm năm 2014 với vị trí giám đốc công nghệ (CTO). Nhưng sau một năm thì OKcoin phá sản. Không nản chí với thất bại của OKcoin, CZ lặng lẽ nuôi tham vọng thành lập công ty tiền mã hóa của riêng mình. Kết quả, ông thành lập sàn giao dịch tiền điện tử lớn nhất thế giới nhờ quyết định đúng đắn khi tham gia vào đợt bùng nổ xu hướng của ICO năm 2017.</p>

      <p>Trong năm 2021, Changpeng Zhao đã liên tục tạo ra những xu hướng mới trong thị trường tiền điện tử và thu hút được một cộng đồng người theo dẽo hùng hậu trên Twitter. Ông tiếp tục giữ vững vị thế của sàn giao dịch crypto hàng đầu thế giới của Binance. CZ còn - <u>Tạo điều kiện phát triển Binance Smart Chain (BSC)</u> - blockchain có phí thấp, khả năng mở rộng cao. Đồng thời phát triển đồng BNB từ đồng coin sàn Binance trở thành Token của toàn hệ sinh thái Binance Smart Chain.</p>
      <h3><strong>Sam Bankman-Fried</strong></h3>
      <br/>
      <p><>CEO / Nhà sáng lập</></p>
      <p><>Sàn giao dịch tiền mã hóa FTX</></p>
      <p><>Năm sinh: 1992</></p>
      <p><>Quốc tịch: Hoa Kỳ</></p>

      <p>Sam Bankman-Fried (1992) l<u>à tỷ phú crypto giàu nhất nước Mỹ</u>, theo thống kê của <i>Forbes </i>đồng thời là người sáng lập và giám đốc điều hành của quỹ đầu tư Alameda Research và sàn giao dịch tiền điện tử FTX.</p>

      <p>Bankman-Fried đã thành lập sàn giao dịch tiền điện tử và phái sinh FTX vào năm 2019, đã nhanh chóng phát triển trở thành sàn giao dịch lớn thứ sáu theo khối lượng, tự hào được định giá 3,5 tỷ đô la. Ông cũng là một nhà từ thiện nổi tiếng và có thể có nguyện vọng ảnh hưởng chính trị, quyên góp 5,2 triệu đô la cho chiến dịch năm 2020 của Tổng thống Joe Biden.</p>

      <p>Vào năm 2021, Sam Bankman-Fried đã biến FTX trở thành một trong những nền nền tảng giao dịch crypto hàng đầu, cạnh tranh với Binance. Không chỉ thế,Sam còn đóng góp cho cộng đồng để gia tăng mức độ nhận thức về tiền mã hóa thông qua các <u>thỏa thuận quảng bá với nhiều thương hiệu lớn</u> và các <u>đợt huy động vốn khổng lồ</u></p>
      <h3><strong>Elon Musk</strong></h3>
      <br/>
      <p><>CEO</></p>
      <p><>Tesla / SpaceX</></p>
      <p><>Năm sinh: 1971</></p>
      <p><>Quốc tịch: Nam Phi / Hoa Kỳ</></p>

      <p>Elon Musk (1971) là một tỷ phú nổi tiếng trên thế giới, là giám đốc điều hành của công ty xe điện lớn nhất thế giới Tesla và công ty hàng không SpaceX cũng tham gia và có sức ảnh hưởng cực lớn đối với thị trường tiền điện tử năm 2021. Ông còn là <u>Nhân vật của Năm 2021</u><u> </u>do <i>Tạp chí TIME</i> bình chọn</p>

      <p>Xuyên suốt năm 2021, Elon Musk đã ảnh hưởng rất lớn đối với thị trường crypto qua động thái ủng hộ Bitcoin trên mạng xã hội, dẫn đến việc công ty <u>Tesla đầu tư 1,5 tỷ USD vào BTC</u> và <u>chấp nhận thanh toán Bitcoin</u>. Nhưng không lâu sau đó, ông đã chỉ ra những điểm yếu của Bitcoin về điện năng và ảnh hưởng xấu đến môi trường nên Tesla đã ngừng chấp nhận Bitcoin. Elon Musk đã chyển sang <u>hậu thuẫn Dogecoin (DOGE)</u> vì cho rằng đồng tiền memecoin này có nhiều tiềm năng để trở thành &quot;đồng tiền chung của Internet&quot;. Qua tất cả hành động và sự thay đổi thất thường của ông đã cho các nhà đầu tư trong thị trường crypto bài học không phải lúc nào cũng nên tin người nổi tiếng trên mạng xã hội.</p>
      <h3><strong>Jack Dorsey</strong></h3>
      <br/>
      <p><>CEO</></p>
      <p><>Công ty xử lý thanh toán Block (tên cũ là Square)</></p>
      <p><>Cựu CEO của Twitter</></p>

      <p>Jack Dorsey, là người thành lập nên mạng xã hội Twitter có số lượng người sử dụng nhiều nhất hiện nay, cũng là một người có tiếng nói trong thị trường tiền điện tử. Mặc dù hiện tại ông đã từ nhiệm vị trí chủ tịch của công ty Twitter, nhưng ông vẫn còn là là chủ tịch của công ty xử lý thanh toàn Block ( tên cũ là Square). Lý do ông từ nhiệm vì muốn dành nhiều thời gian hơn trong thị trường tiền điện tử.</p>

      <p>Về cá nhân Jack Dorsey, ông là một người ủng hộ lớn đối với Bitcoin và cho rằng Bitcoin sẽ trở thành đồng tiền chung của thế giới Internet. Vậy nân, trong năm 2021, ông đã chỉ đạo Twitter tích hợp ví Bitcoin vào hồ sơ người dùng mạng xã hội, có kế hoạch hỗ trợ cả địa Ethereum và NFT. Ông còn có các dự định xây dựng <u>sàn DEX</u> và <u>ví cứng</u> cho Bitcoin. Tuy Jack Dorsey tin tưởng vào Bitcoin nhưng ông lại <u>Không tin tưởng vào Web3</u> làm rất nhiều nhà đầu tư hoang mang về xu hướng sắp tới.</p>

      <p><img src="/blog/infulencer/2.png" /></p>
      <h3><strong>Nayib Bukele</strong></h3>
      <br/>
      <p><>Tổng thống</></p>
      <p><>El Salvador</></p>
      <p><>Năm sinh: 1981</></p>
      <p><>Quốc tịch: El Salvador</></p>

      <p>Nayib Bukele (1981) là một vị tổng thống của đất nước El Salvador. Ông đã có một quyết định khá mạo hiểm khi biến<u> El Salvador trở thành quốc gia đầu tiên trên thế giới công nhận Bitcoin</u> làm tiền tệ chính thức. Điều này gây ra ảnh hưởng tích cực với những nhà đầu tư Bitcoin và ảnh hưởng đến các quyết định chính trị của các quốc gia khác trong tương lai.</p>

      <p>Vào năm 2021, sau khi có quyết định công bố Bitcoin là tiền pháp định của đất nước El Salvador thì ông đã tiếp tục ủng hộ Bitcoin bằng cách <u> xây dựng cơ sở đào BT</u>C bằng năng lượng sạch, có<u> các khoản đầu tư vào BT</u>C, sử dụng lợi nhuận đầu tư Bitcoin vào<u> phát triển cơ sở hạ tần</u>g. </p>

      <p>Vào năm 2022, tổng thống El Salvador - Nayib Bukele, có kế hoạch <u>phát hành 1 tỷ USD trái phiếu Bitcoin và xây dựng một thành phố hỗ trợ Bitcoin</u> nhằm hỗ trợ và phát triển Bitcoin thành một loại tiền tệ pháp định có tính thanh khoản cao trong quốc gia.</p>
      <h3><strong>Michael Saylor</strong></h3>
      <br/>
      <p><>CEO</></p>
      <p><>Công ty đầu tư MicroStrategy</></p>
      <p><>Năm sinh: 1965</></p>
      <p><>Quốc tịch: Hoa Kỳ</></p>

      <p>Michael Saylor (1965) hiện tại là một tỷ phú và là Giám đốc điều hành (CEO) của công ty đầu tư MicroStrategy nổi tiếng khi thu mua và sở hữu rất nhiều Bitcoin. Ông đã chi ra một lượng lớn tiền để mua Bitcoin trong năm 2021. Tính đến tháng 12/2021, <u>MicroStrategy đang sở hữu 122.478 BTC</u>, trị giá hơn 6 tỷ USD</p>

      <p>Cá nhân ông Saylor cũng<u> sở hữu hơn 17.700 BT</u>C, trị giá 870 triệu USD</p>
      <h3><strong>Gary Gensler</strong></h3>
      <br/>
      <p><>Chủ tịch</></p>
      <p><>Ủy ban Chứng khoán và Giao dịch Hoa Kỳ (SEC)</></p>
      <p><>Năm sinh: 1957</></p>
      <p><>Quốc tịch: Hoa Kỳ</></p>

      <p>Gary Gensler (1957) không còn xa lạ với thế giới tiền điện tử. Ông là cựu giám đốc đầu tư của Goldman Sachs, từ lâu đã được xem là một ứng cử viên nặng ký để đảm nhận vai trò chủ tịch SEC. Hiện tại, ông đang là chủ tịch của ban chứng khoán và giao dịch Hoa Kỳ (SEC). Ông cũng là một trong những người có sức ảnh hưởng lớn đối với các nhà đầu tư tiền điện tử khi <u>Phê duyệt cho quỹ ETF Bitcoin</u> dựa trên hợp đồng tương lai, tạo điều kiện để<u> BTC lập ATH mới ở 69.000 USD</u>. Ông còn <u>Kêu gọi các nhà lập pháp Mỹ sớm ban hành quy định quản l</u>ý tiền mã hóa để thi trường này chính thống hóa và mọi người đón nhận. Tuy nhiên, Chủ tịch SEC cũng có những quan điểm trái chiều khi có các động thái cứng rắn nhắm vào các công ty crypto được cho là có liên hệ với chứng khoán nh<u>ư Ripp</u>le<u>, Unisw</u>ap<u>, Ter</u>ra<u>, Circ</u>le,..</p>
      <h3><strong>Giới chức Hoa Kỳ</strong></h3>
      <br/>
      <p><>Nhà Trắng, Quốc hội và Bộ Tài chính Hoa Kỳ</></p>

      <p>Trong năm 2021, giới chức hoa kỳ vẫn có sự tranh cải với nhau về vấn đề trong thị trường tiền điện tử. Giới chức Hoa Kỳ đưa ra sự quan ngại về Stablecoin và Yêu cầu <u>điều tra rủi ro xoay quanh stablecoin</u>, tổ chức điều trần cả ở <u>Thượng viện</u> và <u>Hạ viện</u>.Chính phủ Hoa Kỳ Phát hành <u>báo cáo tổng quan về stablecoi</u>n. Không chỉ vậy, họ còn đề xuất luật quả lý Stablecoin và đánh thuế tiền mã hóa làm cả thị trường chao đao.</p>
      <h3><strong>Nguyễn Thành Trung</strong></h3>
      <br/>
      <p><>CEO / Nhà sáng lập</></p>
      <p><>Axie Infinity</></p>
      <p><>Năm sinh: 1992</></p>
      <p><>Quốc tịch: Việt Nam</></p>

      <p>Nguyễn Thành Trung (1992) một trong những tài năng trẻ của đất Việt đã thành lập mô hình game play to earn cực kỳ nổi tiếng trên toàn thế giới - Axie Infinity. Dự án của anh đã mở đầu cho một xu hướng của crypto và đồng thời mở ra một kỷ nguyên mới cho làm game truyền thống.</p>

      <p>Nguyễn Thành Trung là người<u> đặt những viên gạch đầu tiên cho Axie Infinity</u>, xây dựng dự án không ngừng nghỉ kể từ năm 2018. Hiện tại anh đã gặt hái những thành công nhất định. Trong năm 2021, tựa game Axie Infinity có sự đột phá mạnh mẽ làm điên đảo cả thị trường Việt Nam nói riếng và cả thế giới nói chung.</p>

      <p><img src="/blog/infulencer/3.png" /></p>
      <h3><strong>Nguyễn Thế Vinh</strong></h3>
      <br/>

      <p><>CEO / Đồng sáng lập</></p>
      <p><>Coin98 Finance</></p>
      <p><>Năm sinh: 1992</></p>
      <p><>Quốc tịch: Việt Nam</></p>

      <p>Nguyễn Thế Vinh đồng dạng là một tài năng trẻ đến từ Việt Nam. Anh đã sáng lập nên Coin98 Finance, một dự án Super App đã thu hút rất nhiều người dùng trong lĩnh vực tiền điện tử. Năm 2021, ứng dụng ví điện tử Coin98 trở nên phổ biến tại Việt Nam và cộng đồng tiền mã hóa trên thế giới. Với mục tiêu định vị là trở thành một Super App, Nguyễn Thế Vinh đã triển khai nhiều tính anwng cho hệ sinh thái Coin98 gồm: hỗ trợ NFT, sàn DEX, Staking C98, Launchpad. Bên cạnh đó, Coin98 Finance thành công được mở bán trên Binance Lauchpad, với giá trị BN<u>B đăng ký tham gia mua lên đến 1,6 tỷ U</u>SD.</p>
      <h3><strong>Trương Hồng Thi</strong></h3>
      <br/>

      <p><>CEO / Nhà sáng lập</></p>
      <p><>Icetea Labs - Polkafoundry</></p>
      <p><>Năm sinh: 1981</></p>
      <p><>Quốc tịch: Việt Nam</></p>

      <p>Trương Hồng Thi xuất thân tại đất nước Việt Nam và là giám đốc điều hành cũng như là Founder của Icetea Labs và Polkafoundry - một dự án hỗ trợ blockchain Polkadot. Trong năm 2021, Trương Hồng Thi đã có một số thành tự nhất định khi phát triển các dự án như Red Kite và GameFi thành các Launchpad hàng đầu trong cho các game nổi tiếng và các dự án trong thị trường crypto. Đồng thời, anh còn là cố vấn cho rất nhiều dự án gameFi Việt đóng góp rất lớn trong việc thúc đẩy thị trường GameFi Việt phát triển mạnh mẽ trong thời gian cuối năm 2021.</p>
      <h3><strong>Gavin Wood</strong></h3>
      <br/>

      <p><>Nhà sáng lập</></p>
      <p><>Polkadot - Kusama</></p>
      <p><>Năm sinh: 1980</></p>
      <p><>Quốc tịch: Anh Quốc</></p>

      <p>Vơi sự nổi tiếng của dự án Polkadot, mội dự án được rất nhiều người quan tâm trong thời gian gần đây thì cha đẻ của của dự án - Gavin Wood (1980) là một nhân vật chúng tai không thể nào không biết. Gavin Wood có tham vọng đưa Polkadot trở thành một Blockchain mà mọi Blockchain cần phải kết nối. Vào cuối năm 2021, Polkadot đã hoàn tất tiếp nhận 5 parachain đầu tiên sau các cuộc đấu giá đặc biệt yêu cầu dự án tham gia phải khóa DOT lại và điều này làm giảm nguồn cung trong quá trình đấu giá DOT làm giá DOT tăng trưởng nhanh trước khi đấu giá.</p>
    </div>    
);

const coins = (
  <div>
    <h3><strong>Top 12 dự án có sức ảnh hưởng lớn đối với thị trường tiền điện tử</strong></h3>
      <br/>
      <br/>
      <br/>
    <p><img src="/blog/coins/Image_0.png" /></p>

    <p>Năm 2021 là một năm khá đặc biệt đối với thị trường tiền điện tử. Không chỉ có những sự kiện đặc biệt mà còn những những dự án nổi bật đánh dấu sự đổi mới và phát triển không ngừng trong thị trường này. Hãy cùng Coin68 Crypto Choice Awards 2022 tìm hiểu về những dự án và những đồng coin ấn tượng nhất trong năm 2021</p>
    <p><img src="/blog/coins/Image_1.png" /></p>
    <h3><strong>Bitcoin - BTC</strong></h3>
      <br/>


      <p>Bitcoin là một đồng tiền điện tử được phát hành dưới dạng mã nguồn mở và được đưa ra thị trường vào năm 2009 bởi một người tên là Satoshi Nakamoto. Đây cũng chính là đồng tiền điện tử đầu tiên trên thế giới, đặt nền móng cho sự phát triển của thị trường Crypto. </p>

    <p>Trong suốt năm 2021, Bitcoin vẫn giữ được vị trí “ngôi vương” trong thị trường tiền tiện tử khi thị phần vốn hóa của Bitcoin vẫn luôn là cao nhất. Không chỉ vậy, Bitcoin thu hút một lượng lớn nhà đầu tư như các quỹ đầu tư lớn hoặc các nhà đầu tư từ thị trường khác bằng cách tăng trưởng nhanh chóng tới 69.000 đô la. Cho nên, Bitcoin chính là một tượng đài trong thị trường tiền mã hóa.</p>
    <h3><strong>Ethereum - ETH</strong></h3>
      <br/>

      <p><strong>Ethereum (ETH)</strong> là một loại cryptocurrency được xây dựng vào năm 2013 bởi Vitalik Buterin, thường được gọi là Bitcoin 2.0. Đây không chỉ là một đồng tiền tệ mà nó còn là nền tảng tạo ra nhiều ứng dụng khác thông qua ngôn ngữ lập trình của mình.</p>

    <p>Hiện tại, Ethereum là một nền tảng có tổng giá trị được khóa lại lớn nhất thị trường crypto. Vào năm 2021, nền tảng Ethereum tiếp tục tăng trưởng mạnh và đồng thời là động lực thủ đẩy toàn bộ các đồng tiền mã hóa cũng như là nền tảng khởi đầu cho nhiều xu hướng mới trong thị trường tiền điện tử như: NFT, DAO, DeFi, DeFi 2.0, v.v. </p>

    <p>Bởi vì nhận rõ sự bất tiện khi sử dụng công nghệ Proof-of-Work, nền tảng Ethereum đã thực hiện thành công nâng cấp<u> EIP-1559</u>, biến ETH thành đồng tiền giảm phát và từ bước tiến vững chắc trên lộ trình thực hiện sự kiện chuyển sang Proof-of-Stake trong năm 2022.</p>
    <h3><strong>Binance Coin - BNB</strong></h3>
      <br/>

    <p>Binance Coin (BNB) hiểu đơn giản là coin chính thức của sàn Binance, do chính sàn giao dịch này phát hành dưới dạng ký hiệu BNB. Binance Coin ra mắt thông qua cơ chế ICO (initial coin offering – đợt phát hành tiền điện tử đầu tiên) diễn ra vào tháng 7 năm 2017.</p>

    <p>Trong suốt năm 2021, Binance Coin có sự phát triện vượt bậc khi giá trị đồng BNB tăng vọt lên hơn 600$ với tổng vốn hóa top 4 toàn thị trường. Bên cạnh đó, Binance Coin là một nền tảng blockchain có tổng giá trị được khóa lại lớn thứ 2 trên thế giới chỉ sau Ethereum. Binance Coin còn ra một cơ chế đốt BNB BEP-95 nhằm giảm lạm phát trong hệ sinh thái.</p>

    <p>Binance Coin luôn được phát triển lớn mạnh đồng thời là nền tảng đựơc nhiều dự án game blockchain lựa chọn để xây dựng. Tuy nhiêu bên cạnh việc phát triển nhanh chóng, nhưng hệ sinh thái của Binance Coin thường xuyên gặp<u> sự cố về bảo mật</u>. </p>
    <h3><strong>Solana - SOL</strong></h3>
      <br/>

    <p>Solana (SOL) là một dự án có vai trò hỗ trợ các ứng dụng tài chính phi tập trung, blockchain mở và các hợp đồng thông minh với hiệu suất cao. Đây được xem là một trong những dự án blockchain đáng chú ý nhất trong năm 2021. Với sự tăng trưởng chóng mặt, Solana là giải pháp mở rộng cực kỳ thành công cho đến thời điểm hiện tại.</p>

    <p>Blockchain Solana phát triển như một giải pháp có thể thay thế cho nền tảng Etherem với các ưu điểm như thông lượng giao dịch cao, phí thấp để thu hút các dự án DeFi, NFT, Game,... Tuy phát triển nhanh chóng nhưng dự án Solana vẫn <u>nhiều lần gặp sự cố</u> trong năm 2021, nghiêm trọng nhất là lần sập mạng<u> khiến blockchain đứng im hơn 18 tiếng</u>.</p>


    <p><img src="/blog/coins/Image_2.png" /></p>
    <h3><strong>Polygon - MATIC</strong></h3>
      <br/>

      <p>Polygon Networks được xem là giải pháp mở rộng quy mô Layer 2 dựa trên Sidechain và được hỗ trợ bởi Binance và Coinbase. Polygon tập trung vào hệ sinh thái Ethereum, lấy nền tảng này làm chuỗi để kết nối mọi thứ.</p>

    <p>Polygon là một nền tảng tương đối dễ sử dụng, cấu trúc chặt chẽ, chất lượng để mở rộng quy mô Ethereum và phát triển cơ sở hạ tầng. Cho nên Polygon là một ứng viên hàng đầu thay thế cho Ethereum nhờ phí giao dịch thấp, thu hút cả dự án lẫn thanh khoản từ Ethereum. Bên cạnh đó, Polygon còn đầu tư mạnh tay vào các giải pháp Layer-2 để chuẩn bị cho tương lai. Đồng thời sẵn sàng triển khai EIP-1559 giống Ethereum để trở thành một đồng coin giảm phát.</p>
    <h3><strong>Avalanche - AVAX</strong></h3>
      <br/>

    <p>Avalanche (AVAX) được xem là một nền tảng có cấu trúc mạng lưới ô (Umbrella Platform) cho phép khởi chạy các ứng dụng tài chính phi tập trung (DeFi), tài sản tài chính, giao dịch cùng nhiều dịch vụ khác.Dự án  Avalanche thuộc nhóm dự án có concept “Internet of Blockchain” như Cosmos, Polkadot. Đây là một mạng lưới các Blockchain được thiết kế chuyên biệt dựa trên Avalanche Consensus nhằm tạo điều kiện cho nhà đầu tư khám phá, quản lý và giao dịch các tài sản thị trường tư nhân.</p>

    <p>Vào năm 2021, Avalanche là một trong những blockchain có sự đột phá mạnh mẽ với mức tăng trưởng vượt bậc so với đầu năm và bắt đầu chiếm lấy thị phần vốn hóa từ nền tảng Ethereum. Nhờ sự phát triển nhanh chóng và các chương trìnhđ khuyến khích đa dạng , Avanlanche góp phần cho xu hương incentive program trên các hệ sinh thái.</p>
    <h3><strong>Terra - LUNA</strong></h3>
      <br/>

    <p>Terra được biết đến là một Blockchain hỗ trợ hợp đồng thông minh, khai thác tiện ích của công nghệ Cosm Wasm của dự án Cosmos. Bên cạnh đó, dự án được cung cấp bởi đồng tiền LUNA Token bản địa và đồng Terra USD (UST). Chính vì thế, Blockchain Terra trở thành nền tảng xây dựng hấp dẫn cho các ứng dụng dựa trên Blockchain.</p>

    <p>Trong năm 2021, Blockchain dựa án Terra cũng có một bước tăng trưởng nhanh chóng so sánh vơi đầu năm đồng thời cũng chiếm lấy một phần thị phần vốn hóa của Etherem. Bên cạnh đó Blockchain Terra bức phá mạnh mẽ trong quý 4 năm 2021 nhờ <u>nâng cấp Columbus-5</u>, <u>đề xuất đốt LUNA</u> và <u>chuyển giá trị sang stablecoin UST</u>. </p>
    <h3><strong>Dogecoin - DOGE</strong></h3>
      <br/>

    <p>Dogecoin (ký hiệu: DOGE) là một đồng tiền kỹ thuật số được xây dựng và phát triển trên nền tảng của đồng Litecoin. Đồng coin này ra mắt thị trường từ rất sớm vào ngày 08/12/2013.</p>

    <p>Ý tưởng để thiết kế ra Dogecoin dựa trên meme “Doge”. Với sự ngộ nghĩnh và độc đáo của mình, DOGE từng bị xem như một “trò đùa” trên thị trường tiền mã hóa. Nhưng hiện nay, đây lại là một đồng coin nhận được rất nhiều sự quan tâm từ phía các nhà đầu tư.</p>

    <p>Vào đầu năm 2021, đồng memecoin có sức tăng trưởng hàng đầu Dogecoin, và điều này đã gây ra một sự hứng thú lớn đối với các nhà đầu tư và quỹ đầu tư. Và cũng vào thời gian này, đồng memecoin Dogecoin đã <u> Được Elon Musk hậu thuẫn</u>,<u> Tesla sắp sửa chấp nhận thanh toán</u>, Vitalik Buterin<u> tham gia đội ngũ cố vấn</u>. Nhờ có được sự quan tâm lớn từ cộng đồng các nhà đầu tư, Dogecoin đã từ từ phát triển và đưa ra lộ trình phát triển cụ thể cho năm 2022 bao gồm staking.</p>

    <p><img src="/blog/coins/Image_3.png" /></p>
    <h3><strong>Shiba Inu - SHIB</strong></h3>
      <br/>

      <p>Shiba Inu Coin (SHIB) là một tiền điện tử thuộc dự án Shiba Inu của Nhật và được phát hành ở dạng token ERC20. Ý tưởng tên gọi đồng tiền này được lấy cảm hứng từ chú chó thông minh Shiba tại xứ sở hoa anh đào này, với tham vọng biến loại tiền ảo Shiba Inu thành Doge Killer trên hệ thống tiền điện tử.</p>

    <p>Nhờ tham vọng soán ngôi vị trí của Dogecoin, Shiba Inu cũng có sức tăng trưởng không kém cạnh gì Dogecoin trong năm 2021. Khác với Dogecoin, một coin thuần về memecoin, Shiba Inu có một hệ sinh thái như <u>sàn DEX</u> giao dịch,<u> NFT</u>, mở rộng công dụng. Bên cạnh đó, Shiba Inu cũng ngày được chấp nhận rộng rãi bởi các công ty truyền thống như <u>Travala</u>, <u>Newegg</u>, <u>AMC</u>.</p>

    <h3><strong>Cardano - ADA</strong></h3>
      <br/>

      <p>Cardano là một <u>Proof-of-Stake</u> blockchain platform phi tập trung thế hệ 3.0 do Charles Hoskinson sáng lập, với mục đích thay thế cơ chế Proof-of-work và<u> Ethereum</u> 2.0. </p>

    <p>Trong năm vừa qua. dự án Cardano là cũng phát triển nhanh chóng nhờ việc<u> Thực hiện thành công hard fork Alonzo</u>, chính thức hỗ trợ smart contract, đồng thời nuôi hi vọng trở thành một Ethereum killer trong tương lai. Tuy nhiên, trong quá trình phát triển hệ sinh thái của Cardano vẫn còn đang gặp một số khó khăn nhất định.</p>
    <h3><strong>Polkadot - DOT</strong></h3>
      <br/>

      <p>Polkadot (DOT) là một nền tảng kết nối nhiều blockchain lại với nhau tạo thành một mạng lưới đa chuỗi (multi-chain), không đồng nhất (heterogeneous) và có thể mở rộng. Polkadot cho phép những blockchain này chia sẻ dữ liệu nhằm tạo ra một mạng lưới phi tập trung. Nói cách khác, Polkadot là blockchain của nhiều blockchain riêng lẻ – nơi người dùng có thể tạo ra nền tảng cho riêng mình.</p>

    <p>Năm 2021 là một năm đặc biệt đối với dự án Polkadot khi bắt đầu khởi chạy các phiên đấu giá đầu tiên trên cả Kusama và Polkadot và đạt được các thành công nhất định đánh dấu cho một kỷ nguyên của Polkadot sắp bùng nổ. Hiện tại đã có <u>năm dự án đầu tiên bắt đầu triển khai trên Polkadot</u></p>
    <h3><strong>Axie Infinity - AXS/SLP</strong></h3>
      <br/>

      <p>Axie Infinity (ASX) được biết đến là một vũ trụ thú nuôi kỹ thuật số cực kỳ độc đáo được lấy cảm hứng từ sự kết hợp giữa củ khoai tây và trò chơi Pokémon đã từng “làm mưa làm gió” trên toàn cầu. Game Axie Infinity được xây dựng trên nền tảng Blockchain, nơi mà bất kỳ ai cũng có thể kiếm được token thông qua hoạt động chơi và làm giàu cho hệ sinh thái game. Trong đó, người chơi thoải mái chiến đấu, thu thập, nuôi cũng như xây dựng vương quốc riêng trên đất liền cho thú cưng (còn được gọi là Axies) của mình.</p>

    <p>Vào giữa năm 2021, lúc thị trường đang ảm đạm thì Axie Infinity bắt đầu bùng nổ mạnh mẻ và tạo đà cho một xu hướng mới để dẫn dắt thị trường tiền điện tử phát tiển - xu hướng play to earn. Sau khi Axie Infinity bùng nổ mạnh mẻ kéo thẹo một loạt dự án về game cũng bắt đầu phát triển theo thu hút hàng ngàn game thủ trên toàn cầu và trở thành tiêu điểm bàn luận của giới truyền thông Việt Nam và toàn thế giới.</p>
 
  </div>
);

const events = (
  <div>
    <h3><strong>12 sự kiện có sức ảnh hưởng nhất thị trường tiền mã hóa năm 2021</strong></h3>
      <br/>
      <br/>
      <br/>
    <p><img src="/blog/events/0.png" /></p>

    <h3><strong>Toàn bộ năm 2021: Sự trỗi dậy của các dự án "Ethereum killer" - BSC, SOL, AVAX, LUNA, DOT, FTM, CELO</strong></h3>
      <br/>

    <p>Khi sự giao dịch trên Blockchain Ethereum bị hạn chế bởi các phí GAS quá cao, và thời gian giao dịch bị kéo dài, thì sự ra đời của các giải pháp thay thế như<i> BSC, SOL, AVAX, LUNA, DOT, FTM, CELO</i> bắt đầu phát triển mạnh mẽ và nhận được nhiều sự quan tâm đến từ các nhà đầu tư tiền mã hóa. Những Blockchain này được ví như “Ethereum Killer” và dần dần chiếm lấy thị phần thị trường từ Ethereum.</p>
    <h3><strong>Tháng 3/2021: Sự bùng nổ của các NFT, NFT của Beeple được bán với giá kỷ lục 69,3 triệu USD</strong></h3>
      <br/>

    <p>Tác phẩm kỹ thuật số sử dụng công nghệ NFT :”Every Day: The First 5000 Days” của nghệ sỹ Beeple đã được bán với giá 69.3 triệu USD - một mức giá cao nhất cho đến thời điểm đó. Tác phẩm đã thu hút được sự quan tâm cực lớn từ phần lớn các nà đầu tư, đến các nghệ sỹ. Không chỉ thế, tác phẩm còn đánh dấu cho một xu hướng phát triển mới của cộng - thị trường NFT.</p>
    <h3><strong>14/04/2021: Coinbase IPO</strong></h3>
      <br/>

    <p>Vào ngày 14/04/201, sàn giao dịch tiền mã hóa Coinbase được niêm yết trên sàn chứng khoán NASDAQ của Mỹ dưới dạng Direct Public Offering. Đây cách thức phát hành cổ phiếu mà tổ chức phát hành trực tiếp bán cổ phiếu cho nhà đầu tư mà không thông qua tổ chức trung gian tài chính.</p>

    <p>Coinbase được niêm yết trên sàn chứng khoán NASDAQ của Mỹ được ví như một sự công nhận đối với việc đầu tư tài sản kỹ thuật số và ảnh hưởng đến với những người đầu tư có quan điểm truyền thống trong thị trường tài chính.</p>
    <h3><strong>12/05/2021: Elon Musk quay đầu với Bitcoin</strong></h3>
      <br/>

    <p>Ngày 12/05, tỷ phú Elon Musk cho biết nguyên nhân Tesla quyết định ngưng sử dụng Bitcoin làm tiền tệ thanh toán là vì công ty quan ngại việc sử dụng nhiên liệu hóa thạch để khai thác Bitcoin.</p>

    <p>Những người đào bitcoin được gọi là các "thợ mỏ". Để có thể thu về bitcoin, họ sẽ phải giải quyết các vấn đề tính toán phức tạp thông qua một "máy đào" tiêu tốn nhiều điện năng, và những điện năng được khai thác bởi những phương thức không lành mạnh và gây ô nhiễm tới môi trường.</p>

    <p><img src="/blog/events/1.png" /></p>
    <h3><strong>19/05/2021: Big dump</strong></h3>
      <br/>

      <p>Sau khi sự kiện Tesla ngừng chấp nhận Bitcoin làm phương thức thanh toàn, thì Trung Quốc bắt đầu đưa ra các quan điểm về việc cấm giao dịch crypto trên toàn quốc và ra các dự luật về việc cấm đào Bitcoin tại Trung Quốc làm các thợ đào phải “tắt máy” và di chuyển đến đất nước khác. Cũng tại thời gian này, Bitcoin đã có một cú sụt giảm cực sâu làm hoảng loạn đến tất cả các nhà đầu tư.</p>

    <h3><strong>Tháng 7/2021: Sự trỗi dậy của Axie Infinity và xu hướng Play to Earn</strong></h3>
      <br/>

    <p>Trong khi thị trường tiền mã hóa còn ảm đạm khi vẫn còn ảnh hưởng từ đợt sụp đổ lớn vào tháng 5, thì mảng game Play-to-Earn - dẫn đầu là game Axie Infinity - đã trỗi dậy và bùng nổ hơn bao giờ hết khi thu hút mọi sự chú ý. Game Axie Infinity đã kích hoạt lên xu hướng về GameFi, Metaverse, và đồng thời định nghĩa mô hình play-to-earn tạo ra sự đột phá trong làng game truyền thống.</p>

    <p>Chơi game không còn là hành vi giết thời gian của người tiêu dùng nữa, mà thay vào đó, người chơi giống như đang làm việc, cống hiến thời gian, sức lao động và kiếm thu nhập cho gia đình. Axie Infinity là một ví dụ điển hình. Điều quan trọng nhất là, đặc biệt là trong đại dịch COVID-19, các nhóm nghèo cần thu nhập phụ thường được hưởng lợi nhiều nhất.</p>
    <h3><strong>05/08/2021: Ethereum ra mắt EIP-1559</strong></h3>
      <br/>

    <p>Vào tối ngày 05/08/2021 (giờ Việt Nam), mạng lưới Ethereum đã khai thác thành công block số 12965000, đánh dấu việc hard fork London và nâng cấp EIP-1559 được triển khai.</p>

    <p>EIP-1559 là một đề xuất nhằm thay đổi hoàn toàn cách tính phí của Ethereum, cùng với đó là giảm thiểu nguồn cung của Ethereum trên thị trường bằng cách đốt ETH dưới dạng phí giao dịch. Giải pháp này lần đầu được đề xuất bởi Vitalik Buterin – cha đẻ của Ethereum. </p>
    <h3><strong>06/09/2021: El Salvador công nhận Bitcoin đồng tiền pháp định</strong></h3>
      <br/>

    <p>El Salvador đã thông qua luật công nhận Bitcoin làm tiền vào ngày 07/09, qua đó biến quốc gia Trung Mỹ này trở thành đất nước có chủ quyền đầu tiên lưu hành Bitcoin như một loại tiền tệ hợp pháp trong giao dịch. Sự kiện này đóng góp rất lớn cho việc phát triển và sự chấp thuận của các quốc gia đối với Bitcoin.</p>
    <p><img src="/blog/events/2.png" /></p>
    <h3><strong>Tháng 9/2021: Trung Quốc cấm giao dịch tất cả các thể loại tiền mã hóa</strong></h3>
      <br/>

    <p>Tuần cuối tháng 09/2021, 10 cơ quan Chính phủ Trung Quốc, bao gồm ngân hàng trung ương cùng các cơ quan quản lý ngân hàng, chứng khoán và ngoại hối đã ra tuyên bố chung, rằng họ sẽ hợp tác chặt chẽ để siết chặt các hoạt động liên quan đến tiền mã hóa.</p>

    <p>Ngân hàng Nhân dân Trung Quốc (PBoC) cho biết tiền mã hóa không được lưu thông trên thị trường như tiền tệ truyền thống và cấm các sàn giao dịch ở nước ngoài cung cấp dịch vụ cho các nhà đầu tư ở Trung Quốc đại lục thông qua Internet.</p>

    <p>PBoC cũng cấm các tổ chức tài chính, công ty thanh toán và công ty Internet tạo điều kiện cho các giao dịch crypto.</p>

    <h3><strong>19/10/2021: Quỹ ETF Bitcoin đầu tiên của Hoa Kỳ đi vào giao dịch</strong></h3>
      <br/>

      <p>Mặc dù Uỷ ban Chứng khoán và Giao dịch Mỹ (SEC) chưa từng ra thông báo chính thức phê duyệt việc thành lập quỹ ETF hợp đồng tương lai Bitcoin, nhưng ProShares là đơn vị đầu tiên đưa Bitcoin ETF vào giao dịch chính thức từ ngày 19/10. Động thái này chưa gặp phải sự ngăn cản nào từ SEC, chứng tỏ cơ quan này đã “bật đèn xanh” cho Bitcoin ETF.</p>

    <p>Việc sử dụng quỹ ETF BITO sẽ mở rộng phạm vi tiếp xúc với Bitcoin cho một bộ phận lớn các nhà đầu tư có tài khoản môi giới và thoải mái mua cổ phiếu và ETF, nhưng không muốn trải qua quá trình phức tạp và khó khăn khi thiết lập một tài khoản khác với một nhà cung cấp tiền mã hóa,... hoặc lo ngại rằng các nhà cung cấp này có thể không được kiểm soát và chịu rủi ro bảo mật.</p>

    <h3><strong>29/10/2021: Facebook đổi tên thành Meta, dẫn đầu làn sóng phát triển Metaverse</strong></h3>
      <br/>

    <p>Ngày 28/10, Facebook đã đổi tên công ty thành Meta, đánh dấu lần đổi thương hiệu lớn đầu tiên của gã khổng lồ truyền thông xã hội hàng đầu thế giới. Đây là bước đầu tiên để Facebook bước tiếp kỷ nguyên mới - Metaverse.</p>
    <h3><strong>10/11/2021: Vốn hóa thị trường đạt kỷ lục 2900 tỷ USD, BTC đạt ATH 69000 USD</strong></h3>
      <br/>

      <p>Thị trường tiền mã hóa phát triển từ còn nhỏ yếu đến bằng 1/4 vốn hóa của thị trường vàng, đánh dấu cho sự phát triển không ngừng nghỉ của thị trường đầy tiềm năng này.</p>

  </div>
);

const community = (
  <div>
    <h3><strong>Cộng đồng của năm</strong></h3>
    <p>Cộng đồng là một phần không thể thiếu được trong thị trường tiền mã hóa, đặc biệt là trong thời điểm thị trường trở lại mạnh mẽ sau một mùa đông dài, một thế hệ nhà đầu tư mới đã sẵn sàng tham gia vào thế giới blockchain - crypto một cách thông tin, có tìm hiểu nhưng vẫn cực kỳ sẵn sàng cho những rủi ro.</p>
    <p><img src="/blog/community/0.png" /></p>
    <p>Trên hành trình phát triển của thị trường, việc xuất hiện những cộng đồng cùng chia sẻ sự quan tâm đến thị trường tiền mã, được phát triển một cách bền vững và có định hướng là một điều cực kỳ cần thiết.</p>
    <p>Trong đề cử Cộng đồng của năm nay, hãy cùng Coin68 vinh danh những cộng đồng tiền mã hóa nổi bật nhất tại Việt Nam:</p>
    <p><a href="https://t.me/TradeCoinUnderGround" target="_blank">Trade Coin UnderGround</a></p>
    <p><a href="https://t.me/vietnamtradecoin" target="_blank">TradeCoin VietNam</a></p>
    <p><a href="https://t.me/mgtradinggroup" target="_blank">MG Trading</a></p>
    <p><a href="https://t.me/Coin98Insights_Chat" target="_blank">Coin98 Insights</a></p>
    <p><a href="https://t.me/HCCapital_Chat" target="_blank">HC Capital</a></p>
    <p><a href="https://t.me/Group_68Trading" target="_blank">68 Trading</a></p>
    <p><a href="https://t.me/HiddenGemChat" target="_blank">Hidden Gems</a>	</p>
    <p><a href="https://t.me/ThuanCapital" target="_blank">ThuanCapital</a>	</p>
    <p><a href="https://t.me/danchoicoingroup" target="_blank">Danchoicoin</a>	</p>
    <p><a href="https://t.me/CryptoFamilyVN" target="_blank">Crypto Family Group</a>	</p>
  </div>
);

const recentPosts = [
  {
    title: 'Nhân vật của năm', 
    image: "/NVcuanam.png", 
    slug: '/chi-tiet-bai-viet/nhan-vat-cua-nam',
  }, 
  {
    title: 'Sự kiện của năm', 
    image: "/blog/events/0.png", 
    slug: '/chi-tiet-bai-viet/su-kien-cua-nam',
  }, 
  {
    title: 'Dự án của năm', 
    image: '/DuAn.png',
    slug: '/chi-tiet-bai-viet/du-an-cua-nam',
  }, 
  {
    title: 'Cộng đồng của năm', 
    image: '/CongDong.png',
    slug: '/chi-tiet-bai-viet/cong-dong-cua-nam',
  }
]; 

export {
  infulencer, 
  coins, 
  events, 
  recentPosts, 
  community, 
}; 