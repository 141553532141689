"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.handleVote = exports.deleteTopic = exports.updateUser = exports.createUser = exports.getUser = exports.getUsers = exports.setup = exports.newTopic = exports.updateTopic = exports.getTopicItems = exports.getTopic = exports.getTopics = exports.app = exports.firebaseConfig = exports.topicData = void 0;
var lodash_1 = require("lodash");
var app_1 = require("firebase/app");
var database_1 = require("firebase/database");
var lite_1 = require("firebase/firestore/lite");
exports.topicData = {
    influencer: [
        {
            topicName: 'influencer',
            title: 'Changpeng Zhao',
            image: '/infulencer/Changpeng Zhao.png',
            people: 0,
            content: " <p>- Tiếp tục giữ vững vị thế sàn giao dịch tiền mã hóa lớn nhất thế giới của Binance</p> <p>- Tạo điều kiện phát triển Binance Smart Chain (BSC) - blockchain có phí thấp, khả năng mở rộng cao</p> <p> - Thay đổi BNB từ đồng coin riêng của sàn Binance trở thành token của toàn hệ sinh thái BSC </p>"
        },
        {
            topicName: 'influencer',
            title: 'Sam Bankman-Fried',
            image: '/infulencer/Sam Bankman-Fried.png',
            people: 0,
            content: "<p>- Biến FTX trở thành một trong những nền tảng giao dịch crypto hàng đầu, cạnh tranh với Binance </p> <p>- Gia tăng mức độ nhận thức về tiền mã hóa thông qua các thỏa thuận quảng bá với nhiều thương hiệu lớn và các đợt huy động vốn khổng lồ</p> <p>- Là tỷ phú crypto giàu nhất nước Mỹ, theo thống kê của Forbes</p>"
        },
        {
            topicName: 'influencer',
            title: 'Elon Musk',
            image: '/infulencer/Elon Musk.png',
            people: 0,
            content: "<p> - C\u00F3 \u0111\u1ED9ng th\u00E1i \u1EE7ng h\u1ED9 Bitcoin tr\u00EAn m\u1EA1ng x\u00E3 h\u1ED9i, d\u1EABn \u0111\u1EBFn vi\u1EC7c c\u00F4ng ty Tesla \u0111\u1EA7u t\u01B0 1,5 t\u1EF7 USD v\u00E0o BTC v\u00E0 ch\u1EA5p nh\u1EADn thanh to\u00E1n Bitcoin</p><p> - Ch\u1EC9 ra \u0111i\u1EC3m y\u1EBFu ti\u00EAu t\u1ED1n \u0111i\u1EC7n n\u0103ng c\u1EE7a Bitcoin, Tesla ng\u1EEBng ch\u1EA5p nh\u1EADn BTC</p><p> - Chuy\u1EC3n sang h\u1EADu thu\u1EABn Dogecoin (DOGE) v\u00EC cho r\u1EB1ng \u0111\u1ED3ng ti\u1EC1n memecoin n\u00E0y c\u00F3 nhi\u1EC1u ti\u1EC1m n\u0103ng \u0111\u1EC3 tr\u1EDF th\u00E0nh \"\u0111\u1ED3ng ti\u1EC1n chung c\u1EE7a Internet\"</p><p> - Cho c\u1ED9ng \u0111\u1ED3ng crypto th\u1EA5y kh\u00F4ng ph\u1EA3i l\u00FAc n\u00E0o c\u0169ng n\u00EAn tin l\u1EDDi ng\u01B0\u1EDDi n\u1ED5i ti\u1EBFng tr\u00EAn m\u1EA1ng x\u00E3 h\u1ED9i</p><p>- L\u00E0 Nh\u00E2n v\u1EADt c\u1EE7a N\u0103m 2021 do T\u1EA1p ch\u00ED TIME b\u00ECnh ch\u1ECDn</p>"
        },
        {
            topicName: 'influencer',
            title: 'Jack Dorsey',
            image: '/infulencer/Jack Dorsey.png',
            people: 0,
            content: "<p>- \u1EE6ng h\u1ED9 Bitcoin tr\u00EAn g\u00F3c \u0111\u1ED9 c\u00E1 nh\u00E2n, cho r\u1EB1ng \u0111\u00E2y s\u1EBD tr\u1EDF th\u00E0nh \"\u0111\u1ED3ng ti\u1EC1n chung c\u1EE7a Internet\"</p><p> - Ch\u1EC9 \u0111\u1EA1o Twitter t\u00EDch h\u1EE3p v\u00ED Bitcoin v\u00E0o h\u1ED3 s\u01A1 ng\u01B0\u1EDDi d\u00F9ng m\u1EA1ng x\u00E3 h\u1ED9i, c\u00F3 k\u1EBF ho\u1EA1ch h\u1ED7 tr\u1EE3 c\u1EA3 \u0111\u1ECBa ch\u1EC9 Ethereum v\u00E0 NFT</p><p> - D\u1EF1 \u0111\u1ECBnh x\u00E2y d\u1EF1ng s\u00E0n DEX v\u00E0 v\u00ED c\u1EE9ng cho Bitcoin</p><p> - Kh\u00F4ng tin t\u01B0\u1EDFng v\u00E0o Web3</p>"
        },
        {
            topicName: 'influencer',
            title: 'nguyenthanhtrung',
            hintPlace: 'Nguyễn Thành Trung',
            image: '/infulencer/nguyenthanhtrung.png',
            people: 0,
            content: "<p>- Ng\u01B0\u1EDDi \u0111\u1EB7t nh\u1EEFng vi\u00EAn g\u1EA1ch \u0111\u1EA7u ti\u00EAn cho Axie Infinity, x\u00E2y d\u1EF1ng d\u1EF1 \u00E1n kh\u00F4ng ng\u1EEBng ngh\u1EC9 k\u1EC3 t\u1EEB n\u0103m 2018</p><p>- Gi\u00FAp \u0111\u01B0a Axie Infinity \u0111\u1ED9t ph\u00E1 trong n\u0103m 2021, kh\u1EDFi \u0111\u1EA7u cho xu h\u01B0\u1EDBng Play-to-Earn khuynh \u0111\u1EA3o th\u1ECB tr\u01B0\u1EDDng crypto qu\u1ED1c t\u1EBF l\u1EABn Vi\u1EC7t Nam</p>"
        },
        {
            topicName: 'influencer',
            title: 'nguyenthevinh',
            hintPlace: 'Nguyễn Thế Vinh',
            image: '/infulencer/nguyenthevinh.png',
            people: 0,
            content: "<p>- Gi\u00FAp Coin98 g\u1EB7t h\u00E1i \u0111\u01B0\u1EE3c nhi\u1EC1u th\u00E0nh c\u00F4ng trong n\u0103m 2021, tr\u1EDF th\u00E0nh \u1EE9ng d\u1EE5ng v\u00ED ti\u1EC1n m\u00E3 h\u00F3a ph\u1ED5 bi\u1EBFn t\u1EA1i Vi\u1EC7t Nam v\u00E0 v\u1EDBi c\u1ED9ng \u0111\u1ED3ng ti\u1EC1n m\u00E3 h\u00F3a th\u1EBF gi\u1EDBi</p><p>- Tri\u1EC3n khai th\u00EAm nhi\u1EC1u t\u00EDnh n\u0103ng cho h\u1EC7 sinh th\u00E1i Coin98, g\u1ED3m h\u1ED7 tr\u1EE3 NFT, s\u00E0n DEX, staking C98, launchpad,... </p><p>- \u0110\u01B0a token C98 l\u00EAn m\u1EDF b\u00E1n tr\u00EAn Binance Launchpad, v\u1EDBi gi\u00E1 tr\u1ECB BNB \u0111\u0103ng k\u00FD tham gia mua l\u00EAn \u0111\u1EBFn 1,6 t\u1EF7 USD.</p>"
        },
        {
            topicName: 'influencer',
            title: 'Thi Truong',
            image: '/infulencer/Thi Truong.png',
            people: 0,
            content: "<p>- Ph\u00E1t tri\u1EC3n Red Kite v\u00E0 GameFi th\u00E0nh c\u00E1c launchpad h\u00E0ng \u0111\u1EA7u trong m\u1EA3ng game n\u00F3i ri\u00EAng v\u00E0 crypto n\u00F3i chung </p><p>- L\u00E0m c\u1ED1 v\u1EA5n cho r\u1EA5t nhi\u1EC1u d\u1EF1 \u00E1n GameFi Vi\u1EC7t</p>"
        },
        {
            topicName: 'influencer',
            title: 'Gavin Wood',
            image: '/infulencer/Gavin Wood.png',
            people: 0,
            content: "<p>- \u0110\u01B0a Polkadot hi\u1EC7n th\u1EF1c h\u00F3a tham v\u1ECDng l\u00E0m blockchain, ti\u1EBFp nh\u1EADn 5 parachain \u0111\u1EA7u ti\u00EAn</p><p>- T\u1ED5 ch\u1EE9c c\u00E1c cu\u1ED9c \u0111\u1EA5u gi\u00E1 parachain Polkadot v\u1EDBi y\u00EAu c\u1EA7u kh\u00F3a DOT l\u1EA1i</p>"
        },
        {
            topicName: 'influencer',
            title: 'Nayib Bukele',
            image: '/infulencer/Nayib Bukele.png',
            people: 0,
            content: "<p>- Bi\u1EBFn El Salvador tr\u1EDF th\u00E0nh qu\u1ED1c gia \u0111\u1EA7u ti\u00EAn tr\u00EAn th\u1EBF gi\u1EDBi c\u00F4ng nh\u1EADn Bitcoin l\u00E0m ti\u1EC1n t\u1EC7 ch\u00EDnh th\u1EE9c</p><p>- C\u00F3 nhi\u1EC1u \u0111\u1ED9ng th\u00E1i \u1EE7ng h\u1ED9 Bitcoin sau \u0111\u00F3, g\u1ED3m x\u00E2y d\u1EF1ng c\u01A1 s\u1EDF \u0111\u00E0o BTC b\u1EB1ng n\u0103ng l\u01B0\u1EE3ng s\u1EA1ch, c\u00F3 c\u00E1c kho\u1EA3n \u0111\u1EA7u t\u01B0 v\u00E0o BTC, s\u1EED d\u1EE5ng l\u1EE3i nhu\u1EADn \u0111\u1EA7u t\u01B0 Bitcoin v\u00E0o ph\u00E1t tri\u1EC3n c\u01A1 s\u1EDF h\u1EA1 t\u1EA7ng </p><p>- K\u1EBF ho\u1EA1ch trong n\u0103m 2022: ph\u00E1t h\u00E0nh 1 t\u1EF7 USD tr\u00E1i phi\u1EBFu Bitcoin v\u00E0 x\u00E2y d\u1EF1ng m\u1ED9t th\u00E0nh ph\u1ED1 h\u1ED7 tr\u1EE3 Bitcoin</p>"
        },
        {
            topicName: 'influencer',
            title: 'Michael Saylor',
            image: '/infulencer/Michael Saylor.png',
            people: 0,
            content: "<p>- Ti\u1EBFp t\u1EE5c l\u00E0 \u0111\u01A1n v\u1ECB chi m\u1ED9t l\u01B0\u1EE3ng l\u1EDBn ti\u1EC1n \u0111\u1EC3 mua Bitcoin trong n\u0103m 2021: t\u00EDnh \u0111\u1EBFn th\u00E1ng 12/2021, MicroStrategy \u0111ang s\u1EDF h\u1EEFu 122.478 BTC, tr\u1ECB gi\u00E1 h\u01A1n 6 t\u1EF7 USD </p> <p>- C\u00E1 nh\u00E2n \u00F4ng Saylor c\u0169ng s\u1EDF h\u1EEFu h\u01A1n 17.700 BTC, tr\u1ECB gi\u00E1 870 tri\u1EC7u USD</p>"
        },
        {
            topicName: 'influencer',
            title: 'Gary Gensler',
            image: '/infulencer/Gary Gensler.png',
            people: 0,
            content: "<p>- Ph\u00EA duy\u1EC7t cho qu\u1EF9 ETF Bitcoin d\u1EF1a tr\u00EAn h\u1EE3p \u0111\u1ED3ng t\u01B0\u01A1ng lai, t\u1EA1o \u0111i\u1EC1u ki\u1EC7n \u0111\u1EC3 BTC l\u1EADp ATH m\u1EDBi \u1EDF 69.000 USD</p><p>- K\u00EAu g\u1ECDi c\u00E1c nh\u00E0 l\u1EADp ph\u00E1p M\u1EF9 s\u1EDBm ban h\u00E0nh quy \u0111\u1ECBnh qu\u1EA3n l\u00FD ti\u1EC1n m\u00E3 h\u00F3a </p><p>- C\u00F3 c\u00E1c \u0111\u1ED9ng th\u00E1i c\u1EE9ng r\u1EAFn nh\u1EAFm v\u00E0o c\u00E1c c\u00F4ng ty crypto \u0111\u01B0\u1EE3c cho l\u00E0 c\u00F3 li\u00EAn h\u1EC7 v\u1EDBi ch\u1EE9ng kho\u00E1n nh\u01B0 Ripple, Uniswap, Terra, Circle,..</p>"
        },
        {
            topicName: 'influencer',
            title: 'USA',
            hintPlace: 'Giới chức hoa kì',
            image: '/infulencer/USA.png',
            people: 0,
            content: "<p>- Y\u00EAu c\u1EA7u \u0111i\u1EC1u tra r\u1EE7i ro xoay quanh stablecoin, t\u1ED5 ch\u1EE9c \u0111i\u1EC1u tr\u1EA7n c\u1EA3 \u1EDF Th\u01B0\u1EE3ng vi\u1EC7n v\u00E0 H\u1EA1 vi\u1EC7n</p> <p>- Ph\u00E1t h\u00E0nh b\u00E1o c\u00E1o t\u1ED5ng quan v\u1EC1 stablecoin</p> <p>- \u0110\u1EC1 xu\u1EA5t ban h\u00E0nh lu\u1EADt qu\u1EA3n l\u00FD stablecoin</p> <p>- Ban h\u00E0nh lu\u1EADt c\u00F3 \u0111i\u1EC1u kho\u1EA3n \u0111\u00E1nh thu\u1EBF ti\u1EC1n m\u00E3 h\u00F3a</p>"
        },
        // {
        //   topicName: 'influencer', 
        //   title: 'Nguyễn Thành Trung', 
        //   image: '/infulencer/nguyenthanhtrung.png', 
        //   people: 0, 
        //   content: `<p>- Người đặt những viên gạch đầu tiên cho Axie Infinity, xây dựng dự án không ngừng nghỉ kể từ năm 2018</p><p>- Giúp đưa Axie Infinity đột phá trong năm 2021, khởi đầu cho xu hướng Play-to-Earn khuynh đảo thị trường crypto quốc tế lẫn Việt Nam</p>`
        // },
        // {
        //   topicName: 'influencer', 
        //   title: 'Nguyễn Thế Vinh', 
        //   image: '/infulencer/nguyenthevinh.png', 
        //   people: 0, 
        //   content: `<p>- Giúp Coin98 gặt hái được nhiều thành công trong năm 2021, trở thành ứng dụng ví tiền mã hóa phổ biến tại Việt Nam và với cộng đồng tiền mã hóa thế giới</p><p>- Triển khai thêm nhiều tính năng cho hệ sinh thái Coin98, gồm hỗ trợ NFT, sàn DEX, staking C98, launchpad,... </p><p>- Đưa token C98 lên mở bán trên Binance Launchpad, với giá trị BNB đăng ký tham gia mua lên đến 1,6 tỷ USD.</p>`
        // },
        // {
        //   topicName: 'influencer', 
        //   title: 'Thi Truong', 
        //   image: '/infulencer/Thi Truong.png', 
        //   people: 0, 
        //   content: `<p>- Phát triển Red Kite và GameFi thành các launchpad hàng đầu trong mảng game nói riêng và crypto nói chung </p><p>- Làm cố vấn cho rất nhiều dự án GameFi Việt</p>`
        // },
        // {
        //   topicName: 'influencer', 
        //   title: 'Gavin Wood', 
        //   image: '/infulencer/Gavin Wood.png', 
        //   people: 0, 
        //   content: `<p>- Đưa Polkadot hiện thực hóa tham vọng làm blockchain, tiếp nhận 5 parachain đầu tiên</p><p>- Tổ chức các cuộc đấu giá parachain Polkadot với yêu cầu khóa DOT lại</p>`
        // }, 
    ],
    project: [
        {
            topicName: 'project',
            title: 'Bitcoin',
            special: 'BTC',
            image: '/project/BTC.png',
            people: 0,
            content: "<p>- Ti\u1EBFp t\u1EE5c gi\u1EEF v\u1EEFng \"ng\u00F4i v\u01B0\u01A1ng\" trong ng\u00E0nh crypto</p> <p>- L\u00E0 c\u00E1i t\u00EAn thu h\u00FAt s\u1EF1 tham gia c\u1EE7a nhi\u1EC1u c\u00E1 nh\u00E2n/t\u1ED5 ch\u1EE9c l\u1EDBn \u0111\u1EBFn v\u1EDBi ng\u00E0nh</p>"
        },
        {
            topicName: 'project',
            title: 'Ethereum',
            special: 'ETH',
            image: '/project/Ethereum.png',
            people: 0,
            content: "<p>- L\u00E0 n\u1EC1n t\u1EA3ng c\u00F3 gi\u00E1 tr\u1ECB kh\u00F3a l\u1EA1i l\u1EDBn nh\u1EA5t ng\u00E0nh crypto\n      </p><p>- Ti\u1EBFp t\u1EE5c l\u00E0 \u0111\u1ED9ng l\u1EF1c th\u00FAc \u0111\u1EA9y ti\u1EBFn b\u1ED9 ti\u1EC1n m\u00E3 h\u00F3a trong n\u0103m 2021, l\u00E0 n\u1EC1n t\u1EA3ng kh\u1EDFi \u0111\u1EA7u cho nhi\u1EC1u xu h\u01B0\u1EDBng m\u1EDBi c\u1EE7a ng\u00E0nh crypto nh\u01B0: NFT, DAO, DeFi 2.0,...\n      </p><p>- Th\u1EF1c hi\u1EC7n th\u00E0nh c\u00F4ng n\u00E2ng c\u1EA5p EIP-1559, bi\u1EBFn ETH th\u00E0nh \u0111\u1ED3ng ti\u1EC1n gi\u1EA3m ph\u00E1t\n      </p><p>- Ti\u1EBFn t\u1EEBng b\u01B0\u1EDBc v\u1EEFng ch\u1EAFc tr\u00EAn l\u1ED9 tr\u00ECnh th\u1EF1c hi\u1EC7n s\u1EF1 ki\u1EC7n chuy\u1EC3n sang Proof-of-Stake trong n\u0103m 2022</p>"
        },
        {
            topicName: 'project',
            title: 'Binance Coin',
            special: 'BNB',
            image: '/project/BNB.png',
            people: 0,
            content: "<p>- Li\u00EAn t\u1EE5c n\u1EAFm gi\u1EEF v\u1ECB tr\u00ED blockchain c\u00F3 TVL l\u1EDBn th\u1EE9 2 th\u1EBF gi\u1EDBi\n      </p><p>- L\u00E0 n\u1EC1n t\u1EA3ng \u0111\u01B0\u1EE3c nhi\u1EC1u d\u1EF1 \u00E1n game blockchain l\u1EF1a ch\u1ECDn\n      </p><p>- G\u1EB7p nhi\u1EC1u s\u1EF1 c\u1ED1 hack\n      <p></p>- Tri\u00EAn khai n\u00E2ng c\u1EA5p BEP-95 nh\u1EB1m \u0111\u1ED1t BNB</p>"
        },
        {
            topicName: 'project',
            title: 'Solana',
            special: 'SOL',
            image: '/project/Solana.png',
            people: 0,
            content: "<p>- Blockchain \u0111\u1ED9t ph\u00E1 trong n\u0103m 2021 v\u1EDBi m\u1EE9c t\u0103ng tr\u01B0\u1EDFng v\u01B0\u1EE3t b\u1EADc sao v\u1EDBi \u0111\u1EA7u n\u0103m\n      </p><p>- T\u1EADn d\u1EE5ng \u01B0u \u0111i\u1EC3m th\u00F4ng l\u01B0\u1EE3ng giao d\u1ECBch cao, ph\u00ED th\u1EA5p \u0111\u1EC3 thu h\u00FAt c\u00E1c d\u1EF1 \u00E1n DeFi, NFT, game,... c\u1EA1nh tranh v\u1EDBi Ethereum\n      </p><p>- M\u1EB7c d\u00F9 v\u1EADy, Solana \u0111\u00E3 nhi\u1EC1u l\u1EA7n g\u1EB7p s\u1EF1 c\u1ED1 trong n\u0103m 2021, nghi\u00EAm tr\u1ECDng nh\u1EA5t l\u00E0 l\u1EA7n s\u1EADp m\u1EA1ng khi\u1EBFn blockchain \u0111\u1EE9ng im h\u01A1n 18 ti\u1EBFng</p>"
        },
        {
            topicName: 'project',
            title: 'Polygon',
            special: 'MATIC',
            image: '/project/Polygon.png',
            people: 0,
            content: "<p>- \u1EE8ng vi\u00EAn h\u00E0ng \u0111\u1EA7u thay th\u1EBF cho Ethereum nh\u1EDD ph\u00ED giao d\u1ECBch th\u1EA5p, thu h\u00FAt c\u1EA3 c\u00E1c d\u1EF1 \u00E1n l\u1EABn thanh kho\u1EA3n t\u1EEB ETH\n      </p><p>- \u0110\u1EA7u t\u01B0 m\u1EA1nh tay v\u00E0o c\u00E1c gi\u1EA3i ph\u00E1p Layer-2 \u0111\u1EC3 chu\u1EA9n b\u1ECB cho t\u01B0\u01A1ng lai\n      </p><p>- S\u1EB5n s\u00E0ng tri\u1EC3n khai n\u00E2ng c\u1EA5p EIP-1559 nh\u1EB1m bi\u1EBFn MATIC th\u00E0nh \u0111\u1ED3ng coin gi\u1EA3m ph\u00E1t</p>"
        },
        {
            topicName: 'project',
            title: 'Avalanche',
            special: 'AVAX',
            image: '/project/Avax.png',
            people: 0,
            content: "<p>- Blockchain \u0111\u1ED9t ph\u00E1 trong n\u0103m 2021 v\u1EDBi m\u1EE9c t\u0103ng tr\u01B0\u1EDFng v\u01B0\u1EE3t b\u1EADc sao v\u1EDBi \u0111\u1EA7u n\u0103m, th\u00E1ch th\u1EE9c Ethereum\n      </p><p>- Kh\u1EDFi \u0111\u1EA7u cho xu h\u01B0\u1EDBng incentive program c\u1EE7a c\u00E1c h\u1EC7 sinh th\u00E1i</p>"
        },
        {
            topicName: 'project',
            title: 'Terra',
            special: 'Luna',
            image: '/project/Luna.png',
            people: 0,
            content: "<p>- Blockchain \u0111\u1ED9t ph\u00E1 trong n\u0103m 2021 v\u1EDBi m\u1EE9c t\u0103ng tr\u01B0\u1EDFng v\u01B0\u1EE3t b\u1EADc sao v\u1EDBi \u0111\u1EA7u n\u0103m, th\u00E1ch th\u1EE9c Ethereum\n      </p><p>- B\u1EE9t ph\u00E1 m\u1EA1nh m\u1EBD trong Q4/2021 nh\u1EDD n\u00E2ng c\u1EA5p Columbus-5, \u0111\u1EC1 xu\u1EA5t \u0111\u1ED1t LUNA v\u00E0 chuy\u1EC3n gi\u00E1 tr\u1ECB sang stablecoin UST</p>"
        },
        {
            topicName: 'project',
            title: 'Dogecoin',
            special: 'DOGE',
            image: '/project/Dogecoin.png',
            people: 0,
            content: "<p>- \u0110\u1ED3ng memecoin c\u00F3 s\u1EE9c t\u0103ng tr\u01B0\u1EDFng h\u00E0ng \u0111\u1EA7u trong n\u0103m 2021\n      </p><p>- \u0110\u01B0\u1EE3c Elon Musk h\u1EADu thu\u1EABn, Tesla s\u1EAFp s\u1EEDa ch\u1EA5p nh\u1EADn thanh to\u00E1n, Vitalik Buterin tham gia \u0111\u1ED9i ng\u0169 c\u1ED1 v\u1EA5n\n      </p><p>- Tr\u00ECnh b\u00E0y l\u1ED9 tr\u00ECnh ph\u00E1t tri\u1EC3n r\u00F5 r\u00E0ng cho n\u0103m 2022, g\u1ED3m tri\u1EC3n khai staking</p>"
        },
        {
            topicName: 'project',
            title: 'Shiba Inu',
            special: 'SHIB',
            image: '/project/Shib.png',
            people: 0,
            content: "<p>- \u0110\u1ED3ng memecoin c\u00F3 s\u1EE9c t\u0103ng tr\u01B0\u1EDFng kh\u00F4ng k\u00E9m g\u00EC Dogecoin\n      </p><p>- Ph\u00E1t tri\u1EC3n th\u00EAm h\u1EC7 sinh th\u00E1i s\u00E0n DEX giao d\u1ECBch, NFT, m\u1EDF r\u1ED9ng c\u00F4ng d\u1EE5ng\n      </p><p>- Ng\u00E0y c\u00E0ng \u0111\u01B0\u1EE3c ch\u1EA5p nh\u1EADn r\u1ED9ng r\u00E3i b\u1EDFi Travala, Newegg, AMC, </p>"
        },
        {
            topicName: 'project',
            title: 'Cardano',
            special: 'ADA',
            image: '/project/Ada.png',
            people: 0,
            content: "<p>- Th\u1EF1c hi\u1EC7n th\u00E0nh c\u00F4ng hard fork Alonzo, ch\u00EDnh th\u1EE9c h\u1ED7 tr\u1EE3 smart contract\n      </p><p>- Ti\u1EBFp t\u1EE5c nu\u00F4i hy v\u1ECDng so\u00E1n ng\u00F4i Ethereum\n      </p><p>- Qu\u00E1 tr\u00ECnh ph\u00E1t tri\u1EC3n h\u1EC7 sinh th\u00E1i v\u1EABn g\u1EB7p kh\u00F3 kh\u0103n</p>"
        },
        {
            topicName: 'project',
            title: 'Polkadot',
            special: 'ADA',
            image: '/project/Polkadot.png',
            people: 0,
            content: "<p>- T\u1ED5 ch\u1EE9c th\u00E0nh c\u00F4ng \u0111\u1EA5u gi\u00E1 parachain tr\u00EAn c\u1EA3 Kusama v\u00E0 Polkadot\n      </p><p>- \u0110\u01B0a lo\u1EA1t 5 d\u1EF1 \u00E1n \u0111\u1EA7u ti\u00EAn tri\u1EC3n khai l\u00EAn blockchain</p>"
        },
        {
            topicName: 'project',
            title: 'Axie Infinity',
            special: 'AXS',
            image: '/project/Axie.png',
            people: 0,
            content: "<p>- Kh\u1EDFi \u0111\u1EA7u xu h\u01B0\u1EDBng Play-to-Earn trong n\u0103m 2021</p>"
        },
    ],
    comunity: [
        {
            topicName: 'comunity',
            title: '68 Trading',
            image: '/community/68 Trading.png',
            people: 0
        },
        {
            topicName: 'comunity',
            title: 'Coin98 Insights',
            image: '/community/Coin98 Insights.png',
            people: 0
        },
        {
            topicName: 'comunity',
            title: 'ThuanCapital',
            image: '/community/ThuanCapital.png',
            people: 0
        },
        {
            topicName: 'comunity',
            title: 'Trade Coin Vietnam',
            image: '/community/Trade Coin Vietnam.png',
            people: 0
        },
        {
            topicName: 'comunity',
            title: 'CryptoFamily',
            image: '/community/CryptoFamily.png',
            people: 0
        },
        {
            topicName: 'comunity',
            title: 'danchoicoin',
            image: '/community/danchoicoin.png',
            people: 0
        },
        // {
        //   topicName: 'comunity', 
        //   title: 'GFS Blockchain Insights', 
        //   image: '/community/GFS Blockchain Insights.png', 
        //   people: 0,   
        // },
        {
            topicName: 'comunity',
            title: 'HC Capital',
            image: '/community/HC Capital.png',
            people: 0
        },
        {
            topicName: 'comunity',
            title: 'Hidden Gem',
            image: '/community/kiwi.png',
            people: 0
        },
        {
            topicName: 'comunity',
            title: 'MG Trading',
            image: '/community/MG Trading.png',
            people: 0
        },
        // {
        //   topicName: 'comunity', 
        //   title: 'PhoTaiChinh', 
        //   image: '/community/PhoTaiChinh.png', 
        //   people: 0,   
        // },
        {
            topicName: 'comunity',
            title: 'Trade Coin Underground',
            image: '/community/Trade Coin Underground.png',
            people: 0
        },
    ],
    event: [
        {
            topicName: 'event',
            title: 'Sự trỗi dậy của các dự án "Ethereum killer" - BSC, SOL, AVAX, LUNA, DOT, FTM, CELO',
            mobile_title: 'Sự trỗi dậy của các dự án "Ethereum Killer',
            image: '/topevent/EthereumKiller.png',
            people: 0,
            content: "<p>Khi sự giao dịch trên Blockchain Ethereum bị hạn chế bởi các phí GAS quá cao, và thời gian giao dịch bị kéo dài, thì sự ra đời của các giải pháp thay thế như BSC, SOL, AVAX, LUNA, DOT bùng nổ đóng góp cho sự phát triển nhanh chóng trong cộng đồng đầu tư tiền điện tử.</p>"
        },
        {
            topicName: 'event',
            title: 'Sự bùng nổ của các NFT, dẫn đầu bởi BAYC. NFT của Beeple được bán với giá kỷ lục 69,3 triệu USD',
            mobile_title: 'Sự bùng nổ của các dự án NFT',
            image: '/topevent/NFT.png',
            people: 0,
            content: "<p>L\u1EA7n \u0111\u1EA7u ti\u00EAn trong l\u1ECBch s\u1EED, m\u1ED9t file h\u00ECnh JPG ch\u1EC9 t\u1ED3n t\u1EA1i tr\u00EAn m\u1EA1ng b\u00E1n \u0111\u1EA5u gi\u00E1 \u0111\u01B0\u1EE3c t\u1EDBi 69,3 tri\u1EC7u USD. T\u00E1c ph\u1EA9m c\u00F3 t\u00EAn \"M\u1ED7i ng\u00E0y - 5.000 ng\u00E0y \u0111\u1EA7u ti\u00EAn\"</p>"
        },
        {
            topicName: 'event',
            title: 'Coinbase IPO',
            image: '/topevent/Coinbase.png',
            people: 0,
            content: "<p>S\u00E0n giao d\u1ECBch l\u1EDBn nh\u1EA5t c\u1EE7a M\u1EF9 \u0111\u00E3 \u0111\u01B0a ti\u1EC1n \u0111i\u1EC7n t\u1EED v\u00E0o t\u00E2m \u0111i\u1EC3m ch\u00FA \u00FD v\u1EDBi vi\u1EC7c ni\u00EAm y\u1EBFt c\u00F4ng khai \u0111\u01B0\u1EE3c mong \u0111\u1EE3i nhi\u1EC1u di\u1EC5n ra tr\u00EAn Nasdaq v\u00E0o th\u00E1ng 4.</p>"
        },
        {
            topicName: 'event',
            title: 'Elon Musk quay đầu với Bitcoin',
            image: '/topevent/ElonMusk_Bitcoin.png',
            people: 0,
            content: "<p>T\u1EC9 ph\u00FA Elon Musk, t\u1ED5ng gi\u00E1m \u0111\u1ED1c C\u00F4ng ty Tesla, v\u1EEBa c\u00F3 tuy\u00EAn b\u1ED1 \"quay l\u01B0ng\" v\u1EDBi \u0111\u1ED3ng ti\u1EC1n \u0111i\u1EC7n t\u1EED bitcoin khi x\u00E1c nh\u1EADn Tesla kh\u00F4ng nh\u1EADn thanh to\u00E1n khi mua xe b\u1EB1ng \u0111\u1ED3ng ti\u1EC1n m\u00E3 h\u00F3a n\u00E0y v\u1EDBi l\u00FD do \u00F4 nhi\u1EC5m m\u00F4i tr\u01B0\u1EDDng</p>"
        },
        {
            topicName: 'event',
            title: 'Big dump',
            image: '/topevent/bigdump.png',
            people: 0,
            content: "<p>Ch\u00EDnh ph\u1EE7 Trung Qu\u1ED1c \u0111\u00E3 \u0111i \u0111\u1EBFn c\u1EF1c \u0111oan khi thanh tr\u1EEBng t\u1EA5t c\u1EA3 c\u00E1c th\u1EE3 \u0111\u00E0o Bitcoin c\u1EE7a m\u00ECnh v\u00E0 nh\u01B0\u1EDDng \u0111\u1EA5t cho M\u1EF9 trong cu\u1ED9c ch\u1EA1y \u0111ua s\u1EE9c m\u1EA1nh b\u0103m to\u00E0n c\u1EA7u. C\u0169ng v\u00E0o th\u1EDDi gian n\u00E0y, Bitcoin \u0111\u00E3 c\u00F3 1 c\u00FA dump c\u1EF1c s\u00E2u v\u00E0 k\u00E9o d\u00E0i \u0111\u1EBFn 4 th\u00E1ng.</p>"
        },
        {
            topicName: 'event',
            title: 'Sự trỗi dậy của Axie Infinity và xu hướng Play to Earn',
            image: '/topevent/Axie Infinity.png',
            people: 0,
            content: "<p>Trong th\u1EDDi gian th\u1ECB tr\u01B0\u1EDDng \u0111ang t\u00EDch l\u0169y \u0111\u1EC3 t\u0103ng tr\u01B0\u1EDFng, nh\u1EEFng game play to earn tr\u1ED7i d\u1EADy m\u00E3nh m\u1EBD nh\u1EB1m thu h\u00FAt ph\u1EA7n l\u1EDBn ng\u01B0\u1EDDi tham gia th\u1ECB tr\u01B0\u1EDDng v\u00E0 ti\u00EAu bi\u1EC3u nh\u1EA5t l\u00E0 game Axie Infinity - m\u1ED9t game \u0111\u01B0\u1EE3c ph\u00E1t tri\u1EC3n b\u1EDFi ng\u01B0\u1EDDi Vi\u1EC7t.</p>"
        },
        {
            topicName: 'event',
            title: 'Ethereum ra mắt EIP - 1559',
            image: '/topevent/EIP.png',
            people: 0,
            content: "<p>V\u00E0o kho\u1EA3ng 07:31 PM ng\u00E0y 05/08/2021 (gi\u1EDD Vi\u1EC7t Nam), m\u1EA1ng l\u01B0\u1EDBi Ethereum \u0111\u00E3 khai th\u00E1c th\u00E0nh c\u00F4ng block s\u1ED1 12965000, \u0111\u00E1nh d\u1EA5u vi\u1EC7c hard fork London v\u00E0 n\u00E2ng c\u1EA5p EIP-1559 \u0111\u00E3 \u0111\u01B0\u1EE3c tri\u1EC3n khai.</p>"
        },
        {
            topicName: 'event',
            title: 'El Salvador công nhận Bitcoin đồng tiền pháp định',
            image: '/topevent/C68_Trading.png',
            people: 0,
            content: "<p>Sau khi Lu\u1EADt Bitcoin \u0111\u01B0\u1EE3c qu\u1ED1c h\u1ED9i th\u00F4ng qua ng\u00E0y 9/6, El Salvador ch\u00EDnh th\u1EE9c tr\u1EDF th\u00E0nh qu\u1ED1c gia \u0111\u1EA7u ti\u00EAn coi \u0111\u1ED3ng ti\u1EC1n s\u1ED1 Bitcoin l\u00E0 \u0111\u1ED3ng ti\u1EC1n h\u1EE3p ph\u00E1p.</p>"
        },
        {
            topicName: 'event',
            title: 'Trung Quốc cấm giao dịch tất cả các thể loại tiền mã hóa',
            image: '/topevent/China bans crypto.png',
            people: 0,
            content: "<p>Ng\u00E2n h\u00E0ng Nh\u00E2n d\u00E2n Trung Qu\u1ED1c (PBOC) v\u1EEBa c\u00F4ng b\u1ED1 l\u1EC7nh c\u1EA5m \u0111\u1ED1i v\u1EDBi t\u1EA5t c\u1EA3 c\u00E1c d\u1ECBch v\u1EE5 v\u00E0 thanh to\u00E1n ti\u1EC1n m\u00E3 h\u00F3a, \u0111\u1EC3 l\u00E0m b\u01B0\u1EDBc \u0111\u1EC7m tr\u01B0\u1EDBc khi tung ra ti\u1EC1n k\u1EF9 thu\u1EADt s\u1ED1 c\u1EE7a ri\u00EAng m\u00ECnh.</p>"
        },
        {
            topicName: 'event',
            title: 'ETF Bitcoin đầu tiên của Hoa Kỳ',
            image: '/topevent/Bitcoin ETF.png',
            people: 0,
            content: "<p>Qu\u1EF9 Bitcoin ETF \u0111\u1EA7u ti\u00EAn c\u1EE7a M\u1EF9 \u0111\u01B0\u1EE3c ProShares \u0111\u01B0a v\u00E0o giao d\u1ECBch tr\u00EAn S\u00E0n Giao d\u1ECBch Ch\u1EE9ng kho\u00E1n New York (NYSE) t\u1EEB ng\u00E0y 19/10, \u0111\u00E1nh d\u1EA5u m\u1ED9t c\u1ED9t m\u1ED1c quan tr\u1ECDng v\u1EDBi ng\u00E0nh c\u00F4ng nghi\u1EC7p ti\u1EC1n \u0111i\u1EC7n t\u1EED, v\u00E0 l\u00E0 kh\u1EDFi \u0111\u1EA7u t\u01B0\u01A1i s\u00E1ng cho c\u00E1c qu\u1EF9 ETF c\u1EE7a c\u00E1c lo\u1EA1i ti\u1EC1n \u0111i\u1EC7n t\u1EED kh\u00E1c.</p>"
        },
        {
            topicName: 'event',
            title: 'Facebook đổi tên thành Meta - Dẫn đầu làn sóng phát triển Metaverse',
            image: '/topevent/Meta.png',
            people: 0,
            content: "<p>Facebook \u0111\u00E3 c\u00F4ng b\u1ED1 v\u00E0o ng\u00E0y 28 th\u00E1ng 10 r\u1EB1ng h\u1ECD \u0111\u00E3 \u0111\u1ED5i t\u00EAn c\u00F4ng ty th\u00E0nh Meta, \u0111\u00E1nh d\u1EA5u l\u1EA7n \u0111\u1ED5i th\u01B0\u01A1ng hi\u1EC7u l\u1EDBn \u0111\u1EA7u ti\u00EAn c\u1EE7a g\u00E3 kh\u1ED5ng l\u1ED3 truy\u1EC1n th\u00F4ng x\u00E3 h\u1ED9i h\u00E0ng \u0111\u1EA7u th\u1EBF gi\u1EDBi.</p>"
        },
        {
            topicName: 'event',
            title: 'Vốn hóa thị trường đạt kỷ lục 2900 tỷ USD, BTC đạt ATH 6900 USD',
            image: '/topevent/Bitcoin.png',
            people: 0,
            content: "<p>Th\u1ECB tr\u01B0\u1EDDng ti\u1EC1n \u0111i\u1EC7n t\u1EED ph\u00E1t tri\u1EC3n t\u1EEB c\u00F2n nh\u1ECF y\u1EBFu \u0111\u1EBFn b\u1EB1ng 1/4 v\u1ED1n h\u00F3a c\u1EE7a th\u1ECB tr\u01B0\u1EDDng v\u00E0ng, \u0111\u00E1nh d\u1EA5u cho s\u1EF1 ph\u00E1t tri\u1EC3n kh\u00F4ng ng\u1EEBng ngh\u1EC9 c\u1EE7a th\u1ECB tr\u01B0\u1EDDng \u0111\u1EA7y ti\u1EC1m n\u0103ng n\u00E0y.</p>"
        },
    ]
};
exports.firebaseConfig = {
    apiKey: "AIzaSyDf3JuZJruBftn1e0Ec47Y4nQbibR97DcA",
    authDomain: "cryptochoiceaward.firebaseapp.com",
    projectId: "cryptochoiceaward",
    storageBucket: "cryptochoiceaward.appspot.com",
    messagingSenderId: "781615624762",
    appId: "1:781615624762:web:e6a176dfbe7a8c2ce1bb03",
    measurementId: "G-KSHMXTM02J"
};
exports.app = (0, app_1.initializeApp)(exports.firebaseConfig);
var database = (0, database_1.getDatabase)(exports.app);
var fireStore = (0, lite_1.getFirestore)(exports.app);
var topicCollection = (0, lite_1.collection)(fireStore, 'topics');
var userCollection = (0, lite_1.collection)(fireStore, 'users');
exports["default"] = database;
function isExistTopic(topicsList, topicName, title) {
    return topicsList.filter(function (item) { return item.topicName === topicName && item.title === title; }).length;
}
function getTopics() {
    return __awaiter(this, void 0, void 0, function () {
        var topics, topicsSnapshot, topicsList;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    topics = (0, lite_1.collection)(fireStore, 'topics');
                    return [4 /*yield*/, (0, lite_1.getDocs)(topics)];
                case 1:
                    topicsSnapshot = _a.sent();
                    topicsList = topicsSnapshot.docs.map(function (doc) { return (__assign(__assign({}, doc.data()), { id: doc.id })); });
                    return [2 /*return*/, topicsList];
            }
        });
    });
}
exports.getTopics = getTopics;
function getTopic(topicName, title) {
    return __awaiter(this, void 0, void 0, function () {
        var topicList;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTopics()];
                case 1:
                    topicList = _a.sent();
                    return [2 /*return*/, topicList === null || topicList === void 0 ? void 0 : topicList.find(function (item) { return (item === null || item === void 0 ? void 0 : item.topicName) === topicName && (item === null || item === void 0 ? void 0 : item.title) === title; })];
            }
        });
    });
}
exports.getTopic = getTopic;
;
function getTopicItems(topicName) {
    return __awaiter(this, void 0, void 0, function () {
        var topics;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTopics()];
                case 1:
                    topics = _a.sent();
                    return [2 /*return*/, topics.filter(function (topic) { return topic.topicName === topicName; })];
            }
        });
    });
}
exports.getTopicItems = getTopicItems;
function updateTopic(id, payload) {
    return __awaiter(this, void 0, void 0, function () {
        var topicRef, result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    console.log(id);
                    topicRef = (0, lite_1.doc)(fireStore, 'topics', id);
                    return [4 /*yield*/, (0, lite_1.updateDoc)(topicRef, payload)];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result];
                case 2:
                    error_1 = _a.sent();
                    console.log('Update topic: ', error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
exports.updateTopic = updateTopic;
function newTopic(payload) {
    return __awaiter(this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, lite_1.addDoc)(topicCollection, payload)];
                case 1: return [2 /*return*/, _b.sent()];
                case 2:
                    _a = _b.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
exports.newTopic = newTopic;
function setup(topicName) {
    return __awaiter(this, void 0, void 0, function () {
        var topicsList, topic, anchor_1, error_2;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTopics()];
                case 1:
                    topicsList = _a.sent();
                    topic = exports.topicData[topicName];
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    if (!topic) {
                        throw new Error('Invalid topc data');
                    }
                    anchor_1 = function (i, collection) { return __awaiter(_this, void 0, void 0, function () {
                        var item, exists;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (i >= topic.length) {
                                        return [2 /*return*/];
                                    }
                                    item = topic[i];
                                    exists = isExistTopic(topicsList, item.topicName, item.title);
                                    if (!!exists) return [3 /*break*/, 2];
                                    return [4 /*yield*/, (0, lite_1.addDoc)(collection, {
                                            title: item === null || item === void 0 ? void 0 : item.title,
                                            topicName: item === null || item === void 0 ? void 0 : item.topicName,
                                            people: item === null || item === void 0 ? void 0 : item.people,
                                            image: item === null || item === void 0 ? void 0 : item.image
                                        })];
                                case 1:
                                    _a.sent();
                                    _a.label = 2;
                                case 2: return [4 /*yield*/, anchor_1(i + 1, topicCollection)];
                                case 3:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    return [4 /*yield*/, anchor_1(0, topicCollection)];
                case 3:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    error_2 = _a.sent();
                    console.log('Error setup topic data: ', error_2);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    });
}
exports.setup = setup;
function handleDITopic(topicName, title, amount) {
    return __awaiter(this, void 0, void 0, function () {
        var topic, payload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getTopic(topicName, title)];
                case 1:
                    topic = _a.sent();
                    if (!topic) {
                        return [2 /*return*/];
                    }
                    payload = __assign(__assign({}, topic), { people: (topic === null || topic === void 0 ? void 0 : topic.people) + amount });
                    return [4 /*yield*/, updateTopic(topic === null || topic === void 0 ? void 0 : topic.id, payload)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
function getUsers() {
    return __awaiter(this, void 0, void 0, function () {
        var users, usersSnapshot;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    users = (0, lite_1.collection)(fireStore, 'users');
                    return [4 /*yield*/, (0, lite_1.getDocs)(users)];
                case 1:
                    usersSnapshot = _a.sent();
                    return [2 /*return*/, usersSnapshot.docs.map(function (doc) { return (__assign(__assign({}, doc.data()), { id: doc.id })); })];
            }
        });
    });
}
exports.getUsers = getUsers;
function getUser(ip) {
    return __awaiter(this, void 0, void 0, function () {
        var userList;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getUsers()];
                case 1:
                    userList = _a.sent();
                    return [2 /*return*/, userList.find(function (item) { return (item === null || item === void 0 ? void 0 : item.ip) === ip; })];
            }
        });
    });
}
exports.getUser = getUser;
function createUser(payload) {
    return __awaiter(this, void 0, void 0, function () {
        var error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, lite_1.addDoc)(userCollection, payload)];
                case 1:
                    _a.sent();
                    return [2 /*return*/, payload];
                case 2:
                    error_3 = _a.sent();
                    console.log('Create user error: ', error_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
exports.createUser = createUser;
function updateUser(id, payload) {
    return __awaiter(this, void 0, void 0, function () {
        var userRef, result, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    userRef = (0, lite_1.doc)(fireStore, 'users', id);
                    return [4 /*yield*/, (0, lite_1.updateDoc)(userRef, payload)];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result];
                case 2:
                    error_4 = _a.sent();
                    console.log('Update user: ', error_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
exports.updateUser = updateUser;
function deleteTopic(id) {
    return __awaiter(this, void 0, void 0, function () {
        var topicRef, result, error_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    topicRef = (0, lite_1.doc)(fireStore, 'topics', id);
                    return [4 /*yield*/, (0, lite_1.deleteDoc)(topicRef)];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result];
                case 2:
                    error_5 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
exports.deleteTopic = deleteTopic;
function handleVote(_a, topicName, title) {
    var _b;
    var ip = _a.ip;
    return __awaiter(this, void 0, void 0, function () {
        var userInfo, userTopicData, topicData, topicPeople;
        var _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    title = title.trim();
                    topicName = topicName.trim();
                    return [4 /*yield*/, getUser(ip)];
                case 1:
                    userInfo = _d.sent();
                    userTopicData = ((_b = userInfo === null || userInfo === void 0 ? void 0 : userInfo.votted) === null || _b === void 0 ? void 0 : _b[topicName]) || [];
                    return [4 /*yield*/, getTopic(topicName, title)];
                case 2:
                    topicData = _d.sent();
                    topicPeople = topicData === null || topicData === void 0 ? void 0 : topicData.people;
                    if (userTopicData === null || userTopicData === void 0 ? void 0 : userTopicData.includes(title)) {
                        userTopicData === null || userTopicData === void 0 ? void 0 : userTopicData.splice(userTopicData === null || userTopicData === void 0 ? void 0 : userTopicData.indexOf(title), 1);
                        topicPeople -= 1;
                    }
                    else {
                        userTopicData.push(title);
                        topicPeople += 1;
                    }
                    topicData = lodash_1["default"].assign(topicData, { people: topicPeople });
                    userInfo = lodash_1["default"].assign(userInfo, { votted: __assign(__assign({}, userInfo === null || userInfo === void 0 ? void 0 : userInfo.votted), (_c = {}, _c[topicName] = userTopicData, _c)) });
                    return [4 /*yield*/, updateTopic(topicData === null || topicData === void 0 ? void 0 : topicData.id, topicData)];
                case 3:
                    _d.sent();
                    return [4 /*yield*/, updateUser(userInfo === null || userInfo === void 0 ? void 0 : userInfo.id, userInfo)];
                case 4:
                    _d.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.handleVote = handleVote;
